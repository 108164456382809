import { useEffect, useRef } from 'react';
import Stats from 'stats.js';
import { useAppSelector } from 'store';

export default function PerformanceMonitor(): JSX.Element {
  const render2Enabled = useAppSelector((state) => state.editor.isRender2Enabled);
  const statsRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const refs = statsRefs.current;
    refs.forEach((panel, index) => {
      const stats = new Stats();
      stats.showPanel(2 - index); // Set panel to show (0 = FPS, 1 = MS, 2 = Memory)

      const dom = stats.dom;
      dom.style.position = 'unset';

      panel.prepend(dom);

      const animate = (): void => {
        stats.begin();
        stats.end();
        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);
    });

    return () => {
      refs.forEach((panel) => {
        panel.innerHTML = '';
      });
    };
  }, [render2Enabled]);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        right: render2Enabled ? '310px' : 0,
        zIndex: '1400',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <div
          ref={(el) => {
            if (!el) return;
            statsRefs.current[0] = el;
          }}
        />
        {!render2Enabled && (
          <>
            <div
              ref={(el) => {
                if (!el) return;
                statsRefs.current[1] = el;
              }}
            />
            <div
              ref={(el) => {
                if (!el) return;
                statsRefs.current[2] = el;
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
