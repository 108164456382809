import { CircularProgress } from '@mui/material';
import { Editor } from 'components/editor';
import { setRender2Mode } from 'editor/editor';
import { useLayoutEffect, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch, useAppSelector } from 'store';

/**
 * Load conditionnaly the 3D or 2D version of Road Editor
 */
export function EditorEngine(): JSX.Element {
  const libCirEnabled = useAppSelector((state) => state.editor.isRender2Enabled);
  const dispatch = useAppDispatch();

  const [EditorLibCir, setEditorLibCir] = useState<(() => JSX.Element) | null>(null);

  useLayoutEffect(() => {
    if (!libCirEnabled) return;

    // if needed, we load the lib3d component
    try {
      import('components/editor/editorLibCir').then(({ EditorLibCir }) => {
        setEditorLibCir(() => EditorLibCir);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while loading libCir', e);
      SnackbarUtils.error(
        `Error while loading the 3D renderer. It is likely to be a cache issue. Please refresh your browser.`
      );

      // we reset the editor to the 2D version
      dispatch(setRender2Mode({ enableRender2: false }));
    }
  }, [dispatch, libCirEnabled]);

  if (!libCirEnabled) {
    return <Editor />;
  }

  if (!EditorLibCir) {
    return <CircularProgress />;
  }

  return <EditorLibCir />;
}
