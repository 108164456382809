import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { OPCConfiguration } from 'simulation/opc';

export interface ProjectSliceState {
  enableDevicePrefManagement: boolean;
  circuitDescription: string;
  circuitVersion: string;
  projectName?: string;
  opcData?: OPCConfiguration;
}

export const projectSliceInitialState: ProjectSliceState = {
  enableDevicePrefManagement: true,
  circuitDescription: '',
  circuitVersion: '0.0.0',
};

const projectSlicePrivate = createSlice({
  initialState: projectSliceInitialState,
  name: 'project',
  reducers: {
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
    },
    setDevicePrefManagement: (state, action: PayloadAction<boolean>) => {
      state.enableDevicePrefManagement = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.circuitDescription = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.circuitVersion = action.payload;
    },
    setDevicePrefManagementFromYJS: (state, action: PayloadAction<boolean>) => {
      state.enableDevicePrefManagement = action.payload;
    },
    setDescriptionFromYJS: (state, action: PayloadAction<string>) => {
      state.circuitDescription = action.payload;
    },
    setVersionFromYJS: (state, action: PayloadAction<string>) => {
      state.circuitVersion = action.payload;
    },
    setOpcData: (state, action: PayloadAction<OPCConfiguration>) => {
      state.opcData = action.payload;
    },
  },
});

export const projectSlice = {
  reducer: projectSlicePrivate.reducer,
  name: projectSlicePrivate.name,
};

export const {
  setDevicePrefManagement,
  setDescription,
  setVersion,
  setDevicePrefManagementFromYJS,
  setDescriptionFromYJS,
  setVersionFromYJS,
  setProjectName,
  setOpcData,
} = projectSlicePrivate.actions;
