import * as Comlink from 'comlink';
import type { SimulationService } from 'components/toolboxes/simulation';
import { balyoSimulationLoadingMsgSchema } from 'models/simulation';
import { isBalyoSimulationPromptMsg } from 'models/simulation.guard';
import { dispatchLoadingSimuationStateDebounced } from 'simulation/group-simulation-messages';
import type { RobotHistoryReset } from 'simulation/simulation';
import { displayPrompt, setRobotHistoryReset } from 'simulation/simulation';
import type { LoadSimulationModule, Simulation } from 'simulation/simulation.model';
import store from 'store';
import { MemoryAllocationError } from 'utils/errors';
import { trimNumbers } from 'utils/string';
import { getErrorsMonitoringAction } from './simu-errors-monitoring-action';
import { SnackbarUtils } from './snackbar.service';

const balyoSimulationPromptType = 'balyosimulation-prompt';
const balyoSimulationLoadingType = 'balyosimulation-loading';

let resolveWaitForSimulationService: undefined | ((value: void | PromiseLike<void>) => void);
export let waitForSimulationService: Promise<void> = new Promise((resolve) => {
  resolveWaitForSimulationService = resolve;
});

export const simulationLoadingStep = {
  stepNb: 0,
  lastStepBrief: '',
};

export let simulationService: SimulationService | undefined = undefined;
export let simulationWorker: Worker | undefined = undefined;

interface CreateSimulationServiceRes {
  simulationService?: SimulationService | undefined;
  simulationWorker: Worker;
}

export function createSimulationService({
  balyoSimulationVersion,
}: {
  balyoSimulationVersion?: string;
}): CreateSimulationServiceRes {
  waitForSimulationService = new Promise((resolve) => {
    resolveWaitForSimulationService = resolve;
  });

  if (typeof simulationWorker !== 'undefined') {
    simulationWorker.terminate();
    simulationWorker = undefined;
  }

  const worker = new Worker(new URL('./simulation.service.worker.ts', import.meta.url), {
    name: `simulation-worker-${Date.now()}`,
  });

  worker.onerror = (e) => {
    // eslint-disable-next-line no-console
    console.error(`Simulation worker error:`, e);

    if (e instanceof MemoryAllocationError) {
      // eslint-disable-next-line no-console
      console.error('Memory allocation error');

      SnackbarUtils.error(`Memory allocation failed. You may run out of memory.`);
    } else {
      SnackbarUtils.error(`An error occurred in the simulation module.`);
    }
  };

  worker.onmessageerror = (e) => {
    // eslint-disable-next-line no-console
    console.warn(`Simulation worker message error:`, e);
  };

  worker.onmessage = async (e) => {
    if (e.data === 'ready') {
      if (resolveWaitForSimulationService) {
        resolveWaitForSimulationService();
      } else {
        // eslint-disable-next-line no-console
        console.error('resolveWaitForSimulationService is undefined');
      }
    } else if (e?.data?.type === balyoSimulationPromptType) {
      if (!simulationService) {
        // eslint-disable-next-line no-console
        console.error('Simulation service not found');

        return;
      }

      const simulationMsg = e.data as unknown;

      if (isBalyoSimulationPromptMsg(simulationMsg)) {
        let shouldWeDisplayPrompt = true;
        let robotIdsResetReport: number[] | undefined = [];
        const isDeadlock = simulationMsg.category === 'Robot-Deadlock';
        if (simulationMsg.stopSimu) {
          const robotId = simulationMsg.parameter;

          const monitoringAction = await getErrorsMonitoringAction(
            isDeadlock ? 'deadlock' : 'robotError',
            { robotId },
            simulationService
          );

          shouldWeDisplayPrompt = monitoringAction.errorMonitoringAction === 'StopSimu';
          robotIdsResetReport = monitoringAction.robotIdsResetReport;
        }

        if (shouldWeDisplayPrompt) {
          store.dispatch(displayPrompt(simulationMsg));
        } else {
          const msg = isDeadlock
            ? 'Deadlock detected but the simulation continues'
            : 'An error occurred but the simulation continues';
          SnackbarUtils.info(msg, {
            autoHideDuration: 1000,
          });

          const robots = store.getState().robots.robots;

          const robotIds = robotIdsResetReport;

          if (!robotIds || robotIds.length < 1) return;

          for (let i = 0; i < robotIds.length; i++) {
            const robotId = robotIds[i];
            const robotPosition = robots[robotId].position;
            const robotName = robots[robotId].name;

            const simulationTime = store.getState().simulation.simulatedTime;

            const robotErrorHistory: RobotHistoryReset = {
              robotName: robotName,
              errorType: isDeadlock ? 'deadlock' : 'robotError',
              position: robotPosition,
              simulationTime: simulationTime,
            };

            store.dispatch(setRobotHistoryReset({ robotHistoryReset: robotErrorHistory }));
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(`balyoSimulationPromptType message is not a BalyoSimulationPromptMsg:`, e.data);
      }
    } else if (e?.data?.type === balyoSimulationLoadingType) {
      const simulationMsgUnknown = e.data as unknown;
      const simulationMsgZod = balyoSimulationLoadingMsgSchema.safeParse(simulationMsgUnknown);
      if (simulationMsgZod.success) {
        const loadingState = simulationMsgZod.data;

        const trimmedBrief = trimNumbers(loadingState.brief);

        const stepNbFromStore = store.getState().simulation.loading?.stepNb;
        if (stepNbFromStore !== undefined && stepNbFromStore > simulationLoadingStep.stepNb) {
          simulationLoadingStep.stepNb = stepNbFromStore;
        }

        if (simulationLoadingStep.lastStepBrief !== trimmedBrief) {
          simulationLoadingStep.stepNb++;
          simulationLoadingStep.lastStepBrief = trimmedBrief;
        }

        dispatchLoadingSimuationStateDebounced({
          title: loadingState.title,
          brief: loadingState.brief,
          percent: loadingState.percent,
          stepNb: simulationLoadingStep.stepNb,
        });
      } else {
        // eslint-disable-next-line no-console
        console.error(`balyoSimulationLoadingType message is not a BalyoSimulationLoadingMsg:`, e.data);
      }
    }
  };

  type SimuReturnObject =
    | {
        simulationService: Simulation;
        loadSimulationModule: LoadSimulationModule;
      }
    | undefined;

  const simulation = Comlink.wrap<SimuReturnObject>(worker);

  simulation.loadSimulationModule({
    balyoSimulationVersion,
  });

  const simuService = simulation.simulationService as unknown as SimulationService;

  ({ simulationService, simulationWorker } = { simulationService: simuService, simulationWorker: worker });

  return {
    simulationService: simuService,
    simulationWorker: worker,
  };
}
