import { getRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import { getAllSlotsOfStation } from 'flows/get-all-slots-station';
import { getSlotCoordinates } from 'flows/get-slot-coordinates';
import { isLibTrackItinerary } from 'models/circuit.guard';
import { nanoid } from 'nanoid';
import { startTransition } from 'react';
import {
  clearAllItineraries,
  defaultPathFlag,
  mergeItineraryAndOptimizePositions,
  type Itinerary,
} from 'routes/routes';
import { CircuitService } from 'services/circuit.service';
import store from 'store';
import { getRobotLayerGroupName } from 'utils/circuit/get-robot-layer-group';
import { calculateCRCOfString } from 'utils/crc/crc-calculate';
import { normalizeAngleToMinusPiPi, toRad } from 'utils/helpers';
import { getPreferenceValue } from 'utils/preferences';

interface ComputeFlowItinerariesProps {
  /** id of the flow to check */
  flowId: string;

  /**
   * should the itineraries positions be saved? (can be used to display them)
   * @default false
   **/
  getItinerariesPositions?: boolean;

  /**
   * should the itineraries be displayed?
   * @default false
   **/
  displayItineraries?: boolean;
}

interface ComputeFlowItinerariesReturn {
  /** the computed itineraries */
  itineraries?: ItineraryOfAFlow[][];
}

export interface ItineraryOfAFlow {
  /** Source position (id) */
  src: string;
  /** Destination position (id) */
  dst: string;
  /** Distance [m] */
  d?: number;
  /** Travel time [s] */
  dt?: number;
  /** is the itinerary possible? */
  ok?: boolean;
  /** List of positions to display the itinerary */
  positions?: Itinerary['positions'];
}

const flowItinerariesProgess: Record<string, number> = {};
let stopProcessingFlowItineraries = false;

/**
 * Check that all slots of a given flow are reachable by computing itineraries
 * @param props - The properties for computing flow itineraries.
 * @returns A promise that resolves to the computed flow itineraries.
 */
export async function computeFlowItineraries(
  props: ComputeFlowItinerariesProps
): Promise<ComputeFlowItinerariesReturn | null> {
  stopProcessingFlowItineraries = false;

  const { flowId, getItinerariesPositions = false, displayItineraries = false } = props;

  const simulationServiceModule = await import('services/simulation.service');
  await simulationServiceModule.waitForSimulationService;

  const simulationService = simulationServiceModule.simulationService;
  if (!simulationService) {
    throw new Error('Simulation service not available');
  }

  if (displayItineraries) {
    startTransition(() => {
      store.dispatch(clearAllItineraries());
    });
  }

  flowItinerariesProgess[flowId] = 0;

  const generateXML = (await import('utils/export/generate-xml')).generateXML;

  // eslint-disable-next-line no-console
  console.log('Generating the xml file...');

  const [xml] = await generateXML({
    zones: CircuitService.zones,
    segments: CircuitService.segments,
    stockZones: CircuitService.stockZones,
    measurers: CircuitService.measurers,
    turns: CircuitService.turns,
    racks: CircuitService.racks,
    points: CircuitService.points,
    pretty: true,
  });

  // eslint-disable-next-line no-console
  console.log('generated');

  // eslint-disable-next-line no-console
  console.log('Loading the circuit in the libtrack...');
  const circuitPtr = await simulationService.allocateUTF8(xml);
  await simulationService._TRACK_WasmWrapper_loadCircuit(circuitPtr);

  const flows = store.getState().flows.flows;
  const stations = store.getState().flows.stations;

  const layerGroups = store.getState().circuit.present.layers.layerGroups;
  const layerGroupsIds = Object.keys(layerGroups);
  const layerGroupsNames = layerGroupsIds.map((layerGroupId) => layerGroups[layerGroupId].name);
  const layerGroupsNamesPtr: number[] = [];
  for (let i = 0; i < layerGroupsNames.length; i++) {
    const layerGroupName = layerGroupsNames[i];
    const layerNamePtr = await simulationService.allocateUTF8(layerGroupName);
    layerGroupsNamesPtr.push(layerNamePtr);
  }

  let lastWorkingLayerGroupIndex: number | undefined = undefined;

  const robots = getRobotsEmulation();
  const pathFlagsPerLayerGroup = new Map<string, number>(); // key = layer group name, value = path flag
  robots.forEach((robot) => {
    const robotLayerGroup = getRobotLayerGroupName(robot);
    if (pathFlagsPerLayerGroup.has(robotLayerGroup)) return;

    try {
      const robotPathFlagStr = getPreferenceValue('navigation/pathFlag', robot.serial);
      if (typeof robotPathFlagStr !== 'string') {
        // eslint-disable-next-line no-console
        console.error(`Invalid path flag for robot ${robot.serial} (${robotPathFlagStr})`);
        throw new Error('Invalid path flag');
      }

      pathFlagsPerLayerGroup.set(robotLayerGroup, parseInt(robotPathFlagStr, 10));
    } catch (e) {}
  });

  const itinerariesHashes = new Set<string>();

  const flow = flows.find((flow) => flow.id === flowId);
  if (!flow) {
    throw new Error('Flow not found');
  }

  const itineraries: ItineraryOfAFlow[][] = [];
  for (let i = 0; i < flow.stations.length - 1; i++) {
    const fromStationId = flow.stations[i].id;
    const toStationId = flow.stations[i + 1].id;

    const fromStations = stations.filter((station) => fromStationId.includes(station.id));
    const toStations = stations.filter((station) => toStationId.includes(station.id));

    if (fromStations.length < 1 || toStations.length < 1) {
      throw new Error(`Station not found, from: ${fromStationId}, to: ${toStationId}`);
    }

    const fromSlotsSet = fromStations.map((fromStation) => getAllSlotsOfStation(fromStation));
    const toSlotsSet = toStations.map((toStation) => getAllSlotsOfStation(toStation));

    const fromSlots: string[] = [];

    for (let y = 0; y < fromSlotsSet.length; y++) {
      fromSlotsSet[y].forEach((slot) => {
        fromSlots.push(slot);
      });
    }

    const toSlots: string[] = [];

    for (let y = 0; y < toSlotsSet.length; y++) {
      toSlotsSet[y].forEach((slot) => {
        toSlots.push(slot);
      });
    }

    itineraries.push([]);

    for (let j = 0; j < fromSlots.length; j++) {
      for (let k = 0; k < toSlots.length; k++) {
        const fromSlot = fromSlots[j];
        const toSlot = toSlots[k];

        const itinerary = {
          src: fromSlot,
          dst: toSlot,
        };

        itineraries[i].push(itinerary);
      }
    }
  }

  const nbItinerariesPerStep = itineraries.map((itineraries) => itineraries.length);
  const nbItinerariesTotal = itineraries.reduce((acc, itineraries) => acc + itineraries.length, 0);

  // eslint-disable-next-line no-console
  console.log(
    `${itineraries.length} steps to compute - number of itineraries per step: ${nbItinerariesPerStep.join(
      ', '
    )} - total: ${nbItinerariesTotal}`
  );

  let nbItinerariesComputed = 0;

  /**
   * To save the results of the itineraries already computed
   * To prevent computing the same itinerary multiple times
   * key = (x1,y1,heading1,x2,y2,heading2)
   * value = the itinerary object
   */
  const computedItineraries = new Map<string, ItineraryOfAFlow>();

  let nbItinerariesFromCache = 0;

  for (let i = 0; i < itineraries.length; i++) {
    const itinerariesStep = itineraries[i];

    for (let j = 0; j < itinerariesStep.length; j++) {
      if (stopProcessingFlowItineraries) return null;

      const itinerary = itinerariesStep[j];

      const { src, dst } = itinerary;

      const srcCoords = getSlotCoordinates(src);
      if (!srcCoords) {
        // eslint-disable-next-line no-console
        console.warn(`Slot ${src} not found for itinerary ${JSON.stringify(itinerary)}}`);
        continue;
      }

      const dstCoords = getSlotCoordinates(dst);
      if (!dstCoords) {
        // eslint-disable-next-line no-console
        console.warn(`Slot ${dst} not found for itinerary ${JSON.stringify(itinerary)}}`);
        continue;
      }

      const srcHeading = normalizeAngleToMinusPiPi(toRad(srcCoords.angle));
      const dstHeading = normalizeAngleToMinusPiPi(toRad(dstCoords.angle));

      const itineraryKey = `${srcCoords.x},${srcCoords.y},${srcHeading},${dstCoords.x},${dstCoords.y},${dstHeading}`;
      if (computedItineraries.has(itineraryKey)) {
        const computedItinerary = computedItineraries.get(itineraryKey);
        if (computedItinerary) {
          itinerary.d = computedItinerary.d;
          itinerary.dt = computedItinerary.dt;
          itinerary.ok = computedItinerary.ok;
          if (getItinerariesPositions || displayItineraries) {
            itinerary.positions = computedItinerary.positions;
          }
        }

        nbItinerariesComputed++;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        nbItinerariesFromCache++;
        flowItinerariesProgess[flowId] = nbItinerariesComputed / nbItinerariesTotal;
        continue;
      }

      let k = 0;

      let itineraryOk = false;
      do {
        const layerGroupIndex = lastWorkingLayerGroupIndex ?? k;
        const layerGroupNamePtr = layerGroupsNamesPtr[layerGroupIndex];

        const pathFlag = pathFlagsPerLayerGroup.get(layerGroupsNames[layerGroupIndex]) ?? defaultPathFlag;

        const resStrPtr = await simulationService._TRACK_WasmWrapper_findItinerary(
          layerGroupNamePtr,
          srcCoords.x,
          srcCoords.y,
          srcHeading,
          dstCoords.x,
          dstCoords.y,
          dstHeading,
          pathFlag
        );
        const resStr = await simulationService.UTF8ToString(resStrPtr);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = (() => {
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return JSON.parse(resStr);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Not a valid json`, e, resStr);
          }
        })();

        if (isLibTrackItinerary(res) && res.errorID === 0) {
          lastWorkingLayerGroupIndex = k;
          itineraryOk = true;

          itinerary.d = res.distance;
          itinerary.dt = res.duration;
          itinerary.ok = true;
          if (getItinerariesPositions || displayItineraries) {
            itinerary.positions = (res.listOfPosition ?? []).map((position) => ({
              x: position.x * 100,
              y: -position.y * 100,
              heading: position.heading,
            }));
          }

          computedItineraries.set(itineraryKey, itinerary);
        } else {
          // itinerary NOK for this layer group
          if (lastWorkingLayerGroupIndex !== undefined) {
            lastWorkingLayerGroupIndex = undefined;
          } else {
            k++;
          }
        }
      } while (!itineraryOk && k < layerGroupsIds.length);

      itinerary.ok = itineraryOk;
      if (!itineraryOk) {
        // eslint-disable-next-line no-console
        console.warn(`Itinerary NOK (for every layer group)`, itinerary);
      }

      nbItinerariesComputed++;

      flowItinerariesProgess[flowId] = nbItinerariesComputed / nbItinerariesTotal;

      const itineraryPositions = itinerary.positions;
      if (
        displayItineraries &&
        itineraryPositions &&
        itinerary.ok &&
        itinerary.d !== undefined &&
        itinerary.dt !== undefined
      ) {
        const itineraryHash = calculateCRCOfString(JSON.stringify(itineraryPositions));
        if (!itinerariesHashes.has(itineraryHash)) {
          itinerariesHashes.add(itineraryHash);

          startTransition(() => {
            store.dispatch(
              mergeItineraryAndOptimizePositions({
                id: nanoid(),
                distance: itinerary.d ?? 0,
                duration: itinerary.dt,
                positions: itineraryPositions,
                error: '',
                errorId: 0,
              })
            );
          });
        }
      }

      if (itinerary.positions && !getItinerariesPositions) {
        delete itinerary.positions;
      }
    }
  }

  // free the memory
  await simulationService._OS_WasmWrapper_free(circuitPtr);
  for (let i = 0; i < layerGroupsNamesPtr.length; i++) {
    const layerGroupNamePtr = layerGroupsNamesPtr[i];
    await simulationService._OS_WasmWrapper_free(layerGroupNamePtr);
  }

  delete flowItinerariesProgess[flowId];

  return {
    itineraries,
  };
}

/**
 * Get the progress of computing flow itineraries for a specific flow.
 * @param flowId - The id of the flow to check.
 * @returns The progress of computing flow itineraries, or undefined if not found.
 */
export function getComputingProgressFlowItineraries(flowId: string): number | undefined {
  return flowItinerariesProgess[flowId];
}

export function stopComputingFlowItineraries(): void {
  stopProcessingFlowItineraries = true;
}
