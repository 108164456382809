import HistoryIcon from '@mui/icons-material/History';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { Station } from 'flows/flows';
import { setStationName } from 'flows/flows';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store';

interface RenameStationProps {
  station: Station;
  formerState?: Station;
  isInvalidName: (newName: string) => boolean;
}
export function RenameStation(props: RenameStationProps): JSX.Element {
  const { station, formerState, isInvalidName } = props;

  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleRevertName = useCallback(() => {
    if (!formerState) return;

    dispatch(
      setStationName({
        id: station.id,
        name: formerState?.name,
      })
    );
  }, [dispatch, formerState, station.id]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (formerState) return;

      if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [formerState, handleRevertName]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const stationName = e.target.value;

      dispatch(
        setStationName({
          id: station.id,
          name: stationName,
        })
      );

      setError(isInvalidName(stationName));
    },
    [dispatch, isInvalidName, station.id]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="station-name"
          label="Station name"
          variant="outlined"
          size="small"
          value={station.name}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          error={error}
          helperText={error ? 'This name is incorrect or already used' : undefined}
          autoFocus
        />
      </FormControl>

      {formerState && (
        <div>
          <Tooltip title="Revert">
            <Box component={'span'}>
              <IconButton aria-label="revert" onClick={handleRevertName} disabled={formerState.name === station.name}>
                <HistoryIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        </div>
      )}
    </>
  );
}
