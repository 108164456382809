import { ImportExport } from '@mui/icons-material';
import { Alert, Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { addFlow, addStation, setFlow, setStation } from 'flows/flows';
import { positionToShape } from 'flows/position-to-shape';
import type { GeoJsonCircuit } from 'models/circuit';
import { useCallback, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addTrigger, setTrigger } from 'simulation/triggers';
import store from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { generateNewUniqueName } from 'utils/export/generate-xml.worker';
import { isDefined } from 'utils/ts/is-defined';

interface SkippedPostion {
  /** Id of the station that contained the skipped position */
  stationId: string;
}

interface AddedOrUpdatedEntity {
  /** Name of the added or updated entity */
  id: string;
  /** Type of the added or updated entity */
  entityType: 'flow' | 'trigger' | 'station' | 'position';
  /** Whether it has been added or updated */
  addedOrUpdated: 'added' | 'updated';
}

interface ImportSimulationConfigFromAnotherCircuitProps {
  circuit: GeoJsonCircuit;
}

interface ImportSimulationConfigFromAnotherCircuitRes {
  skippedPostions: SkippedPostion[];
  addedOrUpdatedFlows: AddedOrUpdatedEntity[];
  addedOrUpdatedTriggers: AddedOrUpdatedEntity[];
  addedOrUpdatedStations: AddedOrUpdatedEntity[];
  addedOrUpdatedPositions: AddedOrUpdatedEntity[];

  nbAddedPositions: number;
  nbUpdatedPositions: number;

  nbAddedFlows: number;
  nbUpdatedFlows: number;

  nbAddedTriggers: number;
  nbUpdatedTriggers: number;

  nbAddedStations: number;
  nbUpdatedStations: number;
}

function importSimulationConfigFromAnotherCircuit(
  props: ImportSimulationConfigFromAnotherCircuitProps
): ImportSimulationConfigFromAnotherCircuitRes {
  const { circuit } = props;
  const dispatch = store.dispatch;

  // eslint-disable-next-line no-console
  console.log('Importing simulation configuration from another circuit', circuit);

  const skippedPostions: SkippedPostion[] = [];

  const addedOrUpdatedFlows: AddedOrUpdatedEntity[] = [];
  const addedOrUpdatedTriggers: AddedOrUpdatedEntity[] = [];
  const addedOrUpdatedStations: AddedOrUpdatedEntity[] = [];
  const addedOrUpdatedPositions: AddedOrUpdatedEntity[] = [];

  const flows = store.getState().flows.flows;
  const stations = store.getState().flows.stations;
  const triggers = store.getState().triggers.triggers;

  const mapNewIds: Record<string, string> = {};

  circuit.properties.stations?.forEach((station) => {
    const existingStation = stations.find((s) => s.id === station.id);
    const existingStationName = stations.find((s) => s.name === station.name);

    const id = existingStation?.id ?? generateShapeId();

    if (!existingStation) {
      mapNewIds[station.id] = id;
    }

    if (existingStationName && !existingStation) {
      station = { ...station, name: generateNewUniqueName(station.name) };
    }

    addedOrUpdatedStations.push({
      id,
      entityType: 'station',
      addedOrUpdated: existingStation ? 'updated' : 'added',
    });

    const uncheckedPositions = station.positions;
    const positions = uncheckedPositions
      .map((position) => {
        // we check that the position name exists in the circuit
        const shape = positionToShape({ position });
        if (!shape) {
          // the position does not exist in this circuit
          skippedPostions.push({
            stationId: id,
          });

          return null;
        }

        addedOrUpdatedPositions.push({
          id: position.id,
          entityType: 'position',
          addedOrUpdated: 'added',
        });

        return position;
      })
      .filter(isDefined);

    const action = existingStation ? setStation : addStation;

    dispatch(action({ ...station, positions, id }));
  });

  circuit.properties.flows?.forEach((flow) => {
    const existingFlow = flows.find((f) => f.id === flow.id);
    const existingFlowName = flows.find((f) => f.name === flow.name);

    const id = existingFlow?.id ?? generateShapeId();

    if (!existingFlow) {
      mapNewIds[flow.id] = id;
    }

    if (existingFlowName && !existingFlow) {
      flow = { ...flow, name: generateNewUniqueName(flow.name) };
    }

    flow.id = id;

    flow.stations.forEach((station) => {
      if (typeof station.id === 'string') {
        station.id = [station.id];
      }
    });

    addedOrUpdatedFlows.push({
      id: id,
      entityType: 'flow',
      addedOrUpdated: existingFlow ? 'updated' : 'added',
    });

    const action = existingFlow ? setFlow : addFlow;

    dispatch(action(flow));
  });

  circuit.properties.triggers?.forEach((trigger) => {
    const existingTrigger = triggers.find((t) => t.id === trigger.id);
    const existingTriggerName = triggers.find((t) => t.name === trigger.name);

    const id = existingTrigger?.id ?? generateShapeId();

    if (!existingTrigger) {
      mapNewIds[trigger.id] = id;
    }

    if (existingTriggerName && !existingTrigger) {
      trigger = { ...trigger, name: generateNewUniqueName(trigger.name) };
    }

    if (trigger.type === 'buffer' || trigger.type === 'interval') {
      const flowIds = Array.isArray(trigger.flowId) ? trigger.flowId : [trigger.flowId];
      const newFlowIds = flowIds
        .map((flowId) => {
          if (mapNewIds[flowId]) {
            return mapNewIds[flowId];
          }

          return null;
        })
        .filter(isDefined);

      trigger.flowId = newFlowIds;
    }

    addedOrUpdatedTriggers.push({
      id,
      entityType: 'trigger',
      addedOrUpdated: existingTrigger ? 'updated' : 'added',
    });

    const action = existingTrigger ? setTrigger : addTrigger;

    dispatch(action(trigger));
  });

  const nbAddedFlows = addedOrUpdatedFlows.filter((entity) => entity.addedOrUpdated === 'added').length;
  const nbUpdatedFlows = addedOrUpdatedFlows.filter((entity) => entity.addedOrUpdated === 'updated').length;
  const nbAddedTriggers = addedOrUpdatedTriggers.filter((entity) => entity.addedOrUpdated === 'added').length;
  const nbUpdatedTriggers = addedOrUpdatedTriggers.filter((entity) => entity.addedOrUpdated === 'updated').length;
  const nbAddedStations = addedOrUpdatedStations.filter((entity) => entity.addedOrUpdated === 'added').length;
  const nbUpdatedStations = addedOrUpdatedStations.filter((entity) => entity.addedOrUpdated === 'updated').length;
  const nbAddedPositions = addedOrUpdatedPositions.filter((entity) => entity.addedOrUpdated === 'added').length;
  const nbUpdatedPositions = addedOrUpdatedPositions.filter((entity) => entity.addedOrUpdated === 'updated').length;

  // eslint-disable-next-line no-console
  console.log('Imported simulation configuration from another circuit', {
    skippedPostions,
    addedOrUpdatedFlows,
    addedOrUpdatedTriggers,
    addedOrUpdatedStations,
    addedOrUpdatedPositions,
    nbAddedPositions,
    nbUpdatedPositions,
    nbAddedFlows,
    nbUpdatedFlows,
    nbAddedTriggers,
    nbUpdatedTriggers,
    nbAddedStations,
    nbUpdatedStations,
  });

  return {
    skippedPostions,
    addedOrUpdatedFlows,
    addedOrUpdatedTriggers,
    addedOrUpdatedStations,
    addedOrUpdatedPositions,
    nbAddedPositions,
    nbUpdatedPositions,
    nbAddedFlows,
    nbUpdatedFlows,
    nbAddedTriggers,
    nbUpdatedTriggers,
    nbAddedStations,
    nbUpdatedStations,
  };
}

export function ImportSimulationConfigBtn(): JSX.Element {
  const [report, setReport] = useState<ImportSimulationConfigFromAnotherCircuitRes | undefined>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleImportSimulation = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.[0];

    if (!file) {
      // eslint-disable-next-line no-console
      console.warn('No file selected');

      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result;
      if (typeof content !== 'string') return;

      let circuit: GeoJsonCircuit;
      try {
        circuit = JSON.parse(content) as GeoJsonCircuit;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        SnackbarUtils.error('The file is not a valid JSON file');

        return;
      }

      const report = importSimulationConfigFromAnotherCircuit({ circuit });
      setReport(report);
    };

    reader.readAsText(file);
  }, []);

  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <ImportExport />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Button sx={{ marginBottom: '0.5rem' }} onClick={handleClick} variant="outlined">
                Import Configuration
              </Button>
              <input
                type="file"
                accept=".geojson"
                ref={inputRef}
                onChange={handleImportSimulation}
                style={{
                  display: 'none',
                }}
              />
            </>
          }
          secondary={
            <>
              Import a simulation configuration from another circuit
              <HelpIconTooltip
                title={
                  <>
                    Simulation configuration will be imported from another circuit.
                    <br />
                    This includes:
                    <ul
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      <li>the stations</li>
                      <li>the flows</li>
                      <li>the triggers</li>
                    </ul>
                    Positions that not found in the new circuit will be ignored and removed from the stations.
                  </>
                }
                sx={{
                  fontSize: '1rem',
                }}
              />
            </>
          }
        />
      </ListItem>

      {report && <ImportSimulationConfigReport report={report} />}
    </>
  );
}

interface ImportSimulationConfigReportProps {
  report: ImportSimulationConfigFromAnotherCircuitRes;
}
function ImportSimulationConfigReport(props: ImportSimulationConfigReportProps): JSX.Element {
  const { report } = props;

  const nbThingsImported =
    report.addedOrUpdatedFlows.length +
    report.addedOrUpdatedTriggers.length +
    report.addedOrUpdatedStations.length +
    report.addedOrUpdatedPositions.length;
  const isThereThingsImported = nbThingsImported > 0;

  return (
    <>
      {isThereThingsImported && (
        <Alert severity="success">
          <ul>
            <li>{report.nbAddedFlows} flows added</li>
            <li>{report.nbUpdatedFlows} flows updated</li>
            <li>{report.nbAddedTriggers} triggers added</li>
            <li>{report.nbUpdatedTriggers} triggers updated</li>
            <li>{report.nbAddedStations} stations added</li>
            <li>{report.nbUpdatedStations} stations updated</li>
            <ul>
              <li>{report.nbAddedPositions} positions added</li>
              <li>{report.nbUpdatedPositions} positions updated</li>
            </ul>
          </ul>
        </Alert>
      )}

      {!isThereThingsImported && (
        <Alert severity="info">
          The simulation configuration from the other circuit was empty. Thereby nothing has been imported.
        </Alert>
      )}

      {report.skippedPostions.length > 0 && (
        <Alert severity="warning">
          <ul>
            <li>{report.skippedPostions.length} positions not found in the circuit and not imported</li>
          </ul>
        </Alert>
      )}
    </>
  );
}
