import { Button, Stack } from '@mui/material';
import { Border } from 'components/utils/border';
import { removeStation, setStation } from 'flows/flows';
import { isEqual } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useMemo } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { AddPositionToStation } from './add-position-station';
import { ListPositionOfStation } from './list-position-of-station';
import { RenameStation } from './rename-station';
import { StationConfigDisplay } from './station';

interface StationFormProps {
  stationConfigDisplay: StationConfigDisplay;
  setStationConfigDisplay: (value: StationConfigDisplay) => void;
}
export function StationForm(props: StationFormProps): JSX.Element {
  const { stationConfigDisplay, setStationConfigDisplay } = props;
  const stations = useAppSelector((state) => state.flows.stations);
  const selectedStationId = useAppSelector((state) => state.flows.selectedStationId);

  const formerState = useMemo(
    () => structuredClone(store.getState().flows.stations.find((s) => s.id === selectedStationId)),
    [selectedStationId]
  );
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const selectedStation = useMemo(() => {
    return stations.find((station) => station.id === selectedStationId);
  }, [selectedStationId, stations]);

  const isInvalidName = useCallback(
    (newName: string | undefined) => {
      const nameWihtoutBlank = newName?.trimStart().trimEnd();
      const existingStation = stations.find((st) => st.name === nameWihtoutBlank && selectedStationId !== st.id);
      if (!nameWihtoutBlank || existingStation) {
        return true;
      }

      return false;
    },
    [selectedStationId, stations]
  );

  // Add station
  const handleCreateStation = useCallback(() => {
    SnackbarUtils.success(`Station "${selectedStation?.name}" created`);

    setStationConfigDisplay(StationConfigDisplay.Default);
  }, [setStationConfigDisplay, selectedStation?.name]);

  const handleCancelAdding = useCallback(() => {
    if (selectedStation) dispatch(removeStation(selectedStation?.id));

    setStationConfigDisplay(StationConfigDisplay.Default);
  }, [dispatch, setStationConfigDisplay, selectedStation]);

  // Edit station
  const handleSaveFlow = useCallback(() => {
    SnackbarUtils.success(`Sation "${selectedStation?.name}" Edited`);

    setStationConfigDisplay(StationConfigDisplay.Default);
  }, [selectedStation?.name, setStationConfigDisplay]);

  const handleCancelEdition = useCallback(() => {
    const hasChanges = isEqual(formerState, selectedStation);

    if (!hasChanges) {
      confirm({
        title: `Keep ${selectedStation?.name} station unchanged?`,
        confirmationText: 'Keep it unchanged',
        cancellationText: 'Let me edit it',
        cancellationButtonProps: {
          color: 'error',
          autoFocus: true,
        },
      })
        .then(() => {
          if (formerState) dispatch(setStation(formerState));
          setStationConfigDisplay(StationConfigDisplay.Default);
        })
        .catch(() => undefined);
    } else {
      setStationConfigDisplay(StationConfigDisplay.Default);
    }
  }, [confirm, dispatch, formerState, selectedStation, setStationConfigDisplay]);

  return (
    <div>
      {selectedStation && (
        <>
          <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
            <RenameStation
              station={selectedStation}
              formerState={stationConfigDisplay === StationConfigDisplay.Edition ? formerState : undefined}
              isInvalidName={isInvalidName}
            />
          </Stack>

          <Border>
            <AddPositionToStation station={selectedStation} />
            <ListPositionOfStation station={selectedStation} />
          </Border>

          {stationConfigDisplay === StationConfigDisplay.Creation && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button variant="text" color="error" onClick={() => handleCancelAdding()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleCreateStation()}
                disabled={isInvalidName(selectedStation.name)}
              >
                Create
              </Button>
            </Stack>
          )}
          {stationConfigDisplay === StationConfigDisplay.Edition && (
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button variant="text" color="error" onClick={handleCancelEdition}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSaveFlow} disabled={isInvalidName(selectedStation.name)}>
                Save
              </Button>
            </Stack>
          )}
        </>
      )}
    </div>
  );
}
