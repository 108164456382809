import { XMLSerializer } from '@xmldom/xmldom';
import { saveProject } from 'components/export/save-project/save-project';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import { localDoc, projectHost, stopSendingInstallXML } from 'multiplayer/globals';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import type { Pallet } from './circuit/default-circuit-shapes';
import { getSettings } from './config';
import { getRecentProjects } from './editor/recent-projects';
import { NotFoundError } from './errors';
import { trimEndChildNodes } from './export/prettify-xml';

interface PreferencesXmlDocument {
  install: Document | undefined;
  models: { model: string; document: Document }[];
  trucks: { serial: string; document: Document }[];
}

interface NoInstallPreferencesXmlFiles {
  installDictionaries: string[];
  models: { model: string; xml: string }[];
  modelsDictionaries: string[];
  trucks: { serial: string; xml: string }[];
  trucksDictionaries: string[];
}

export interface PreferencesXmlFiles extends NoInstallPreferencesXmlFiles {
  install: string;
}

/** Minimum version of SDK version required to export the comboxGen2 */
export const minimumSDKVersionComboxGen2Export = '4.14.0';

function isPreferencesXmlFiles(obj: any): obj is NoInstallPreferencesXmlFiles {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  // Check if the required properties exist
  if (
    !Array.isArray(obj.installDictionaries) ||
    !Array.isArray(obj.models) ||
    !Array.isArray(obj.modelsDictionaries) ||
    !Array.isArray(obj.trucks) ||
    !Array.isArray(obj.trucksDictionaries)
  ) {
    return false;
  }

  // Check the structure of the models array
  for (const model of obj.models) {
    if (typeof model.model !== 'string' || typeof model.xml !== 'string') {
      return false;
    }
  }

  // Check the structure of the trucks array
  for (const truck of obj.trucks) {
    if (typeof truck.serial !== 'string' || typeof truck.xml !== 'string') {
      return false;
    }
  }

  // All checks pass, the object is of type PreferencesXmlFiles
  return true;
}

export interface Truck {
  ID: number;
  serial: string;
  modelName: string;
}

export class PreferencesService {
  private static install: Document | undefined;
  private static installDictionaries: Document[] | undefined;
  private static modelsPrefs: {
    model: string;
    document: Document;
  }[] = [];
  private static modelsDictionaries: Document[] | undefined;
  private static trucksPrefs: {
    serial: string;
    document: Document;
  }[] = [];
  private static trucksDictionaries: Document[] | undefined;

  private static areInstallPrefsLoaded = false;
  private static areModelsPrefsLoaded = false;
  private static areTrucksPrefsLoaded = false;

  private static directoryHandle: FileSystemDirectoryHandle | undefined;
  private static availableCircuits: string[];

  private static autoSaveInterval: number;

  private static trucks: Truck[] = [];
  private static modelNames: string[] = [];

  public static pallets: Pallet[] = [];
  private static palletGroups: string[] = [];

  public static projectName: string | undefined;

  /** Version of NEW4X used in the project (the CORE) */
  private static ProjectNEWVersion: number | undefined;

  private static newPreferencesSet: Record<string, string | string[]> = {};

  public static setDirectoryHandle(directoryHandle?: FileSystemDirectoryHandle): void {
    this.directoryHandle = directoryHandle;
  }

  public static setInitialPref({ xmls, projectName, projectNEWVersion, availableCircuits }): void {
    this.directoryHandle = undefined;

    this.projectName = typeof projectName === 'string' ? projectName : undefined;
    this.ProjectNEWVersion = typeof projectNEWVersion === 'number' ? projectNEWVersion : undefined;

    this.availableCircuits = (availableCircuits as string[]) || [];

    if (!isPreferencesXmlFiles(xmls)) {
      window.dispatchEvent(new CustomEvent('projectLoaded'));

      return;
    }

    this.installDictionaries = xmls.installDictionaries.map((installDictionaryXml) =>
      parseXmlString(installDictionaryXml)
    );
    this.modelsPrefs = xmls.models.map(({ model, xml }) => ({
      model,
      document: parseXmlString(xml),
    }));
    this.modelsDictionaries = xmls.modelsDictionaries.map((modelsDictionaryXml) => parseXmlString(modelsDictionaryXml));
    this.trucksPrefs = xmls.trucks.map(({ serial, xml }) => ({
      serial,
      document: parseXmlString(xml),
    }));
    this.trucksDictionaries = xmls.trucksDictionaries.map((trucksDictionaryXml) => parseXmlString(trucksDictionaryXml));

    this.areInstallPrefsLoaded = true;
    this.areModelsPrefsLoaded = true;
    this.areTrucksPrefsLoaded = true;

    window.dispatchEvent(new CustomEvent('projectLoaded'));
  }

  public static getPreferencesXmlDocument(): PreferencesXmlDocument {
    return {
      install: this.install,
      models: this.modelsPrefs,
      trucks: this.trucksPrefs,
    };
  }

  public static setInstallDocumentFromYJS(install): void {
    if (typeof install !== 'string') return;

    this.install = parseXmlString(install);
  }

  public static async loadDirectoryPrefs({
    keepDirectoryHandle = true,
    circuitName,
  }: {
    keepDirectoryHandle?: boolean;
    circuitName?: string;
  }): Promise<void> {
    // eslint-disable-next-line no-console
    if (!('showOpenFilePicker' in window)) console.warn('Native FileSystem not supported'); // maybe worth it to raise an error?
    const keepSameProject = !!circuitName;

    if (keepSameProject) {
      if (!this.availableCircuits.includes(circuitName.replace('.geojson', ''))) {
        this.availableCircuits.push(circuitName.replace('.geojson', ''));
      }

      this.callbackWhenFullyLoaded();

      return;
    }

    const projectDirectoryHandle =
      keepDirectoryHandle && this.directoryHandle
        ? await this.getDirectoryHandle()
        : await this.changeDirectoryHandle();

    if (!projectDirectoryHandle) {
      throw new AbortController().abort();
    }

    let prefDirectoryHandle: FileSystemDirectoryHandle;
    try {
      prefDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('PREF');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`PREF1: ${e.message}`, 'PREF');
      }

      throw e;
    }

    let dictionaryDirectoryHandle: FileSystemDirectoryHandle | undefined;
    try {
      dictionaryDirectoryHandle = await prefDirectoryHandle.getDirectoryHandle('DICTIONARY');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        // eslint-disable-next-line no-console
        console.warn('No DICTIONARY folder found in the PREF folder');
      } else {
        throw e;
      }
    }

    this.areInstallPrefsLoaded = false;
    this.areModelsPrefsLoaded = false;
    this.areTrucksPrefsLoaded = false;
    this.trucks = [];
    this.modelNames = [];
    this.pallets = [];
    this.palletGroups = [];
    this.newPreferencesSet = {};

    let installFile: File;
    try {
      installFile = await (await prefDirectoryHandle.getFileHandle('install.xml')).getFile();
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`install.xml: ${e.message}`, 'PREF/install.xml');
      }

      throw e;
    }

    const installDictionaryFiles: File[] = [];
    try {
      if (dictionaryDirectoryHandle) {
        const installDictionaryProject = await (await dictionaryDirectoryHandle.getFileHandle('install.xml')).getFile();
        installDictionaryFiles.push(installDictionaryProject);
      }
    } catch (e) {}

    try {
      if (dictionaryDirectoryHandle) {
        const installDictionaryCore = await (await dictionaryDirectoryHandle.getFileHandle('install.xml')).getFile();
        installDictionaryFiles.push(installDictionaryCore);
      }
    } catch (e) {}

    const [installXml, installDictionariesXml] = await Promise.all([
      await installFile.text(),
      Promise.all(installDictionaryFiles.map(async (installDictionaryFile) => await installDictionaryFile.text())),
    ]);

    let circuitDirectoryHandle: FileSystemDirectoryHandle;
    try {
      circuitDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('Circuits');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`Circuits: ${e.message}`, 'Circuits');
      }

      throw e;
    }

    const availableCircuits: string[] = [];

    for await (const entry of circuitDirectoryHandle.values()) {
      if (entry.kind === 'file' && entry.name.endsWith('.geojson')) {
        availableCircuits.push(entry.name.replace('.geojson', ''));
      }
    }

    this.availableCircuits = availableCircuits;

    if (circuitName && !availableCircuits.includes(circuitName.replace('.geojson', ''))) {
      this.availableCircuits.push(circuitName.replace('.geojson', ''));
    }

    try {
      const coreVersionFile = await getCoreVersionFile();

      if (coreVersionFile) {
        const coreVersionTxt = await coreVersionFile.text();
        let version = coreVersionTxt.split('_')[1];
        if (version[0].toLowerCase() !== 'v') {
          // eslint-disable-next-line no-console
          console.error(`coreVersion file is not valid, version should start with V, found: ${version[0]}`);
        } else {
          version = version.slice(1); // we remove the initial V (for version)
          const versionNb = parseFloat(version);

          if (isNaN(versionNb)) {
            // eslint-disable-next-line no-console
            console.error(`coreVersion file is not valid, version should be a number, found: ${version}`);
          } else {
            this.ProjectNEWVersion = versionNb;
          }
        }
      }
    } catch (e) {
      SnackbarUtils.warning('coreVersion file not found in the project root directory');
    }

    if (installXml && installDictionariesXml) {
      this.loadInstallPrefs(installXml, installDictionariesXml);
      await this.loadTrucksPrefs();
      await this.loadModelsPrefs();

      this.callbackWhenFullyLoaded();
    } else {
      throw new Error(
        `An error occured when we tried to access to ${installFile.webkitRelativePath} or ${installDictionaryFiles[0]?.webkitRelativePath}`
      );
    }
  }

  public static loadInstallPrefs(installXml: string, installDictionariesXml: string[]): boolean {
    const install = parseXmlString(installXml);
    const installDictionaries = installDictionariesXml.map((installDictionaryXml) =>
      parseXmlString(installDictionaryXml)
    );

    this.install = install;
    this.installDictionaries = installDictionaries;

    this.areInstallPrefsLoaded = true;

    this.computePallets();

    return this.areInstallPrefsLoaded;
  }

  private static computePallets(): boolean {
    if (!this.areInstallPrefsLoaded) return false;
    if (!this.install) return false;

    const installDoc = this.install;
    const palletGroupPref = installDoc.querySelector('[name=palletGroup]');
    if (!palletGroupPref) return false;

    const palletGroups = palletGroupPref.querySelectorAll('value');
    palletGroups.forEach((palletGroup) => {
      const name = palletGroup.textContent;
      if (name) this.palletGroups.push(name);
    });

    const palletsPref = installDoc.querySelector('[name=pallets]');
    if (!palletsPref) return false;
    const palletsName = palletsPref.querySelectorAll('[name=names] value');
    if (!palletsName.length) return false;

    palletsName.forEach((palletName, i) => {
      const name = palletName.textContent;
      if (!name) return;

      const pallet: Pallet = {
        name,
        palletWidth: parseFloat(
          palletsPref.querySelector(`[name=palletWidth] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        palletLength: parseFloat(
          palletsPref.querySelector(`[name=palletLength] value:nth-child(${i + 1})`)?.textContent || ''
        ),

        centralGapWidth: parseFloat(
          palletsPref.querySelector(`[name=centralGapWidth] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralGapWidthTolMin: parseFloat(
          palletsPref.querySelector(`[name=centralGapWidthTolMin] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralGapWidthTolMax: parseFloat(
          palletsPref.querySelector(`[name=centralGapWidthTolMax] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralGapHeight: parseFloat(
          palletsPref.querySelector(`[name=centralGapHeight] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralGapHeightTol: parseFloat(
          palletsPref.querySelector(`[name=centralGapHeightTol] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralBlockWidth: parseFloat(
          palletsPref.querySelector(`[name=centralBlockWidth] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralBlockWidthTolMin: parseFloat(
          palletsPref.querySelector(`[name=centralBlockWidthTolMin] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        centralBlockWidthTolMax: parseFloat(
          palletsPref.querySelector(`[name=centralBlockWidthTolMax] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        externalBlockWidth: parseFloat(
          palletsPref.querySelector(`[name=externalBlockWidth] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        externalBlockWidthTolMin: parseFloat(
          palletsPref.querySelector(`[name=externalBlockWidthTolMin] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        externalBlockWidthTolMax: parseFloat(
          palletsPref.querySelector(`[name=externalBlockWidthTolMax] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        palletWidthToleranceMin: parseFloat(
          palletsPref.querySelector(`[name=palletWidthToleranceMin] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        palletWidthToleranceMax: parseFloat(
          palletsPref.querySelector(`[name=palletWidthToleranceMax] value:nth-child(${i + 1})`)?.textContent || ''
        ),
        bottomDeckBoardThickness: parseFloat(
          palletsPref.querySelector(`[name=bottomDeckBoardThickness] value:nth-child(${i + 1})`)?.textContent || ''
        ),

        groups: (palletsPref.querySelector(`[name=groupAffiliation] value:nth-child(${i + 1})`)?.textContent || '')
          .split('')
          .map((groupBinDigit, indexDigit) => (groupBinDigit === '1' ? this.palletGroups[indexDigit] : ''))
          .filter((palletName) => !!palletName),
      };

      this.pallets.push(pallet);
    });

    return true;
  }

  public static async loadTrucksPrefs(install?: Document): Promise<boolean> {
    if (!install) {
      if (!this.areInstallPrefsLoaded)
        throw new Error('this.loadTrucksPrefs called without argument but this.areInstallPrefsLoaded=false');
      install = this.install;
    }

    if (!install) {
      return false;
    }

    const serials: string[] = Array.from(install.querySelectorAll('pref[name=trucksSerial] value')).map(
      (el) => el.textContent || ''
    );

    const projectDirectoryHandle = this.directoryHandle as FileSystemDirectoryHandle;
    let prefDirectoryHandle: FileSystemDirectoryHandle;
    try {
      prefDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('PREF');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`PREF2: ${e.message}`, 'PREF');
      }

      throw e;
    }

    let dictionaryDirectoryHandle: FileSystemDirectoryHandle | undefined;
    try {
      dictionaryDirectoryHandle = await prefDirectoryHandle.getDirectoryHandle('DICTIONARY');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('No DICTIONARY folder found in the PREF folder');
    }

    let trucksDirectoryHandle: FileSystemDirectoryHandle;
    try {
      trucksDirectoryHandle = await prefDirectoryHandle.getDirectoryHandle('TRUCKS');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`TRUCKS: ${e.message}`, 'PREF/TRUCKS');
      }

      throw e;
    }

    const trucksFiles: File[] = [];
    for await (const entry of trucksDirectoryHandle.values()) {
      if (entry.kind === 'file' && entry.name.endsWith('.xml')) {
        trucksFiles.push(await entry.getFile());
        serials.push(entry.name.replace('.xml', '')); // we add the serial to the list of serials
      }
    }

    const promises = trucksFiles.map((file) => file.text());
    const trucksXml = await Promise.all(promises);
    const trucksDom = trucksXml.map((xml) => parseXmlString(xml));

    const dictionaries = dictionaryDirectoryHandle
      ? [await (await dictionaryDirectoryHandle.getFileHandle('truck.xml')).getFile()]
      : [];

    try {
      const coreDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('CORE');
      const corePrefDirectoryHandle = await coreDirectoryHandle.getDirectoryHandle('PREF');
      const coreDictionaryDirectoryHandle = await corePrefDirectoryHandle.getDirectoryHandle('DICTIONARY');

      dictionaries.push(await (await coreDictionaryDirectoryHandle.getFileHandle('truck.xml')).getFile());
    } catch (e) {
      SnackbarUtils.warning('Core folder not found in the project root directory');
    }

    // eslint-disable-next-line no-console
    if (!dictionaries || !dictionaries.length) console.warn('No truck.xml dictionary file found');
    const dictionariesXml = await Promise.all(dictionaries.map((dictionary) => dictionary.text()));
    const dictionariesDom = dictionariesXml.map((dictionary) => parseXmlString(dictionary));

    this.trucksPrefs = trucksDom.map((truckDom, index) => {
      return {
        document: truckDom,
        serial: trucksFiles[index].name.replaceAll('.xml', ''),
      };
    });
    this.trucksDictionaries = dictionariesDom;

    this.areTrucksPrefsLoaded = true;

    return this.areTrucksPrefsLoaded;
  }

  public static async loadModelsPrefs(trucks?: Document[]): Promise<boolean> {
    if (!trucks) {
      if (!this.areTrucksPrefsLoaded)
        throw new Error('this.loadModelsPrefs called without argument but this.areTrucksPrefsLoaded=false');
      trucks = this.trucksPrefs.map((truck) => truck.document);
    }

    const modelNamesSet = new Set(trucks.map((truck) => truck.querySelector('pref[name=modelFileName]')?.textContent));
    modelNamesSet.delete(null);
    modelNamesSet.delete(undefined);
    const modelNames: string[] = [...modelNamesSet] as string[];

    const projectDirectoryHandle = this.directoryHandle as FileSystemDirectoryHandle;
    let prefDirectoryHandle: FileSystemDirectoryHandle;
    try {
      prefDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('PREF');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`PREF3: ${e.message}`, 'PREF');
      }

      throw e;
    }

    let dictionaryDirectoryHandle: FileSystemDirectoryHandle | undefined;
    try {
      dictionaryDirectoryHandle = await prefDirectoryHandle.getDirectoryHandle('DICTIONARY');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('No DICTIONARY folder found in the PREF folder');
    }

    let modelsDirectoryHandle: FileSystemDirectoryHandle;
    try {
      modelsDirectoryHandle = await prefDirectoryHandle.getDirectoryHandle('MODELS');
    } catch (e) {
      if (e instanceof Error && e.name === 'NotFoundError') {
        throw new NotFoundError(`MODELS: ${e.message}`, 'PREF/MODELS');
      }

      throw e;
    }

    const modelFiles: File[] = [];
    for await (const entry of modelsDirectoryHandle.values()) {
      if (entry.kind === 'file' && entry.name.endsWith('.xml')) {
        modelFiles.push(await entry.getFile());
        modelNames.push(entry.name.replace('.xml', '')); // we add the serial to the list of serials
      }
    }

    const promises = modelFiles.map((model) => model.text());
    const modelXmls = await Promise.all(promises);
    const modelDoms = modelXmls.map((xml) => parseXmlString(xml));

    const dictionaries = dictionaryDirectoryHandle
      ? [await (await dictionaryDirectoryHandle.getFileHandle('model.xml')).getFile()]
      : [];

    try {
      const coreDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('CORE');
      const corePrefDirectoryHandle = await coreDirectoryHandle.getDirectoryHandle('PREF');
      const coreDictionaryDirectoryHandle = await corePrefDirectoryHandle.getDirectoryHandle('DICTIONARY');

      dictionaries.push(await (await coreDictionaryDirectoryHandle.getFileHandle('model.xml')).getFile());
    } catch {}

    // eslint-disable-next-line no-console
    if (!dictionaries || !dictionaries.length) console.warn('No model.xml dictionary file found');
    const dictionariesXml = await Promise.all(dictionaries.map((dictionary) => dictionary.text()));
    const dictionariesDom = dictionariesXml.map((dictionaryXml) => parseXmlString(dictionaryXml));

    this.modelsPrefs = modelDoms.map((modelDom, index) => ({
      document: modelDom,
      model: modelFiles[index].name.replaceAll('.xml', ''),
    }));
    this.modelsDictionaries = dictionariesDom;

    this.areModelsPrefsLoaded = true;

    return this.areModelsPrefsLoaded;
  }

  public static getModelPreferenceValue(prefName: string, modelName: string): string | string[] {
    if (!this.arePreferencesFullyLoaded()) {
      throw new Error('Preferences not loaded');
    }

    const trucks = this.getTrucks();
    const truckOfThisModel = trucks.find((truck) => truck.modelName === modelName);
    if (!truckOfThisModel) {
      throw new Error(`No truck with the model ${modelName} found`);
    }

    return getPreferenceValue(prefName, truckOfThisModel.serial);
  }

  public static getPreferenceValue(prefName: string, serial?: string, readDictionary = true): string | string[] {
    if (!this.arePreferencesFullyLoaded()) {
      throw new Error('Preferences not loaded');
    }

    if (!serial && !store.getState().multiplayer.multiplayer) {
      if (this.newPreferencesSet[prefName] !== undefined) {
        return this.newPreferencesSet[prefName];
      }
    }

    const splittedPrefName = prefName.split('/');
    let selector = '';
    for (let i = 0; i < splittedPrefName.length - 1; i++) {
      selector += `category[name="${splittedPrefName[i]}"] `;
    }

    selector += `pref[name="${splittedPrefName[splittedPrefName.length - 1]}"]`;

    // if there's a serial, it's a truck pref or a model pref
    if (serial) {
      const truck = this.trucksPrefs.filter((doc) => {
        return doc.serial === serial;
      })[0];

      if (!truck) throw new Error(`Serial ${serial} not found in the trucks list`);
      let pref = truck.document.querySelector(selector);

      if (pref) {
        const values = pref.querySelectorAll('value');
        if (values.length) {
          return Array.from(values).map((el) => el.textContent as string);
        }

        return pref.textContent as string;
      }

      const modelName = truck.document.querySelector('[name=modelFileName]')?.textContent as string;
      const model = this.modelsPrefs.filter((doc) => {
        return doc.model === modelName;
      })[0];
      if (!model) throw new Error(`Model ${modelName} not found in the models list for the serial ${serial}`);
      pref = model.document.querySelector(selector);

      if (pref) {
        const values = pref.querySelectorAll('value');
        if (values.length) {
          return Array.from(values).map((el) => el.textContent as string);
        }

        return pref.textContent as string;
      }

      // not found, we have to look at the dictionary, we'll look at the truck dictionary first
      if (readDictionary && this.trucksDictionaries) {
        const trucksDicts = this.trucksDictionaries;
        trucksDicts.forEach((truckDict) => {
          pref = truckDict.querySelector(selector);

          if (pref) {
            const values = pref.querySelectorAll('defaultValue');
            if (values.length) {
              return Array.from(values).map((el) => el.textContent as string);
            }

            return pref.textContent as string;
          }
        });
      }

      // not found in the truck dictionary, let's look at the model dictionary
      if (readDictionary && this.modelsDictionaries) {
        const modelsDicts = this.modelsDictionaries;
        modelsDicts.forEach((modelDict) => {
          pref = modelDict.querySelector(selector);
          if (!pref) return;

          const values = pref.querySelectorAll('defaultValue');
          if (values.length) {
            return Array.from(values).map((el) => el.textContent as string);
          }

          return pref.textContent as string;
        });
        throw new Error(`Preference ${prefName} not found (serial: ${serial})`);
      }
    }

    // it's an install pref
    // let's look at the general pref file first
    const install = this.install;
    const prefInstall = install?.querySelector(selector);
    if (prefInstall) {
      const values = prefInstall.querySelectorAll('value');
      if (values.length) {
        return Array.from(values).map((el) => el.textContent as string);
      }

      return prefInstall.textContent as string;
    }

    // not in the general pref file, let's look at the install dictionary
    if (readDictionary && this.installDictionaries) {
      const installDicts = this.installDictionaries;
      installDicts.forEach((installDict) => {
        const pref = installDict.querySelector(selector);
        if (!pref) return;
        const values = pref.querySelectorAll('defaultValue');
        if (values.length) {
          return Array.from(values).map((el) => el.textContent as string);
        }

        return pref.textContent as string;
      });
      throw new Error(`Preference ${prefName} not found`);
    }

    throw new Error(`Preference ${prefName} not found`);
  }

  public static getInstallDocument(): Document | undefined {
    if (!this.install) return undefined;

    return this.install;
  }

  /**
   * Change a preference value of the project
   *
   * @param prefName the name of the preference to change, in the form of "category/pref"
   * @param value the new value of the preference
   * @param create do we create te preference if it doesn't exist?
   * @param createType type of the created preference, is it a pref or a category
   * @param write should we write/update the preference file on disk (can be used to optimize if there are multiple setPreferenceValue in a row)
   * @param sourceDocument if provided, we do not read the install.xml file and we use this document instead, we modifiy it in this function (can be used to optimize if there are multiple setPreferenceValue in a row)
   * @returns wether the preference has been changed or not
   *
   * Only for install.xml yet
   *
   * It would be nice to create a function that can set several preference values
   * at once, this would speed up the process by a lot
   */
  public static async setPreferenceValue(
    prefName: string,
    value: string | string[],
    create = false,
    createType: 'pref' | 'category' = 'pref',
    write = true,
    sourceDocument?: Document,
    truckSerial?: string
  ): Promise<[boolean, Document] | [boolean]> {
    const projectDirectoryHandle = await this.getDirectoryHandle();
    const prefDirectoryHandle = await projectDirectoryHandle?.getDirectoryHandle('PREF');
    const installXmlFileHandle = await prefDirectoryHandle?.getFileHandle('install.xml');

    let fileHandle: FileSystemFileHandle | undefined;
    if (truckSerial) {
      const trucksDirectoryHandle = await prefDirectoryHandle?.getDirectoryHandle('TRUCKS');
      const truckFileHandle = await trucksDirectoryHandle?.getFileHandle(`${truckSerial}.xml`);
      fileHandle = truckFileHandle;
    } else {
      fileHandle = installXmlFileHandle;
    }

    let xml: Document;

    if (store.getState().multiplayer.multiplayer) {
      if (!this.install) return [false];

      xml = this.install;
    } else {
      if (!installXmlFileHandle) {
        // eslint-disable-next-line no-console
        console.warn(`No install.xml file handle found`);

        return [false];
      }

      if (!sourceDocument) {
        const file = await installXmlFileHandle.getFile();
        if (!file) {
          // eslint-disable-next-line no-console
          console.warn(`File for install.xml not found`);

          return [false];
        }

        const text = await file.text();
        xml = parseXmlString(text);
      } else {
        xml = sourceDocument;
      }
    }

    const strIndent = '  ';

    /**
     * With the following regex, we look if the preference name is
     * of the shape categ1/categ2/pref[n] with n a natural number
     * meaning the n-th value of the pref
     * It could be useful for editing just a value of a pref that has several values
     * For example this.setPreferenceValue(`communication/TCP/IP_trucks[${robotId}]`, newIP)
     */
    const regexList = /\[\d+\]$/g;
    const splittedPrefName = prefName.split('/');
    const prefNameLast = splittedPrefName[splittedPrefName.length - 1];
    const matchList = prefNameLast.match(regexList);

    let nbList: number | undefined;
    if (matchList) {
      const nbListStr = matchList[0].replace('[', '').replace(']', '');
      nbList = parseInt(nbListStr, 10);
      splittedPrefName[splittedPrefName.length - 1] = prefNameLast.replace(regexList, '');
    }

    let el: Element | null = xml.querySelector('Preferences');
    let formerEl: Element | null = el;
    for (let i = 0; i < splittedPrefName.length; i++) {
      if (!el) {
        if (create) {
          el = xml.createElement('category');
          el.setAttribute('name', splittedPrefName[i - 1]);
          if (formerEl) {
            formerEl.appendChild(el);

            trimEndChildNodes(formerEl);

            el.before(xml.createTextNode('\n'));
            el.before(xml.createTextNode(strIndent.repeat(i)));

            const eol = xml.createTextNode('\n');
            el.after(eol);
            eol.after(xml.createTextNode(strIndent.repeat(i - 1)));
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Preference ${splittedPrefName[i - 1]} (${prefName}) not found`);

          return [false];
        }
      }

      const name = splittedPrefName[i];
      formerEl = el;
      el = el.querySelector(`:scope > [name="${name}"]`);
    }

    if (!el) {
      if (create) {
        el = xml.createElement(createType);
        el.setAttribute('name', splittedPrefName[splittedPrefName.length - 1]);
        if (formerEl) {
          formerEl.appendChild(el);

          trimEndChildNodes(formerEl);

          el.before(xml.createTextNode('\n'));
          el.before(xml.createTextNode(strIndent.repeat(splittedPrefName.length)));
          const eol = xml.createTextNode('\n');
          el.after(eol);
          eol.after(xml.createTextNode(strIndent.repeat(splittedPrefName.length - 1)));
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Preference ${splittedPrefName[splittedPrefName.length - 1]} (${prefName}) not found`);

        return [false];
      }
    }

    if (typeof value === 'string' && nbList !== undefined) {
      const values = el.querySelectorAll('value');
      if (values.length) {
        const valueEl = values[nbList];
        if (valueEl) {
          valueEl.textContent = value;
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Creating a new value element for ${prefName}`);

          const valueEl = xml.createElement('value');
          valueEl.textContent = value;
          el.appendChild(valueEl);
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`An array should have been found for ${prefName}`);

        el.textContent = value;
      }
    } else if (typeof value === 'string') {
      el.innerHTML = value;
    } else if (Array.isArray(value)) {
      const values = el.querySelectorAll('value');
      for (let i = 0; i < value.length; i++) {
        const val = values[i] || xml.createElement('value');
        val.textContent = value[i];

        if (!values[i]) {
          const isWhiteCharBeforeEl = el.textContent?.endsWith('\n');
          el.appendChild(val);
          if (!isWhiteCharBeforeEl) val.before(xml.createTextNode('\n'));
          val.before(xml.createTextNode(strIndent.repeat(splittedPrefName.length + 1)));
          if (i === value.length - 1) {
            const eol = xml.createTextNode('\n');
            val.after(eol);
            eol.after(xml.createTextNode(strIndent.repeat(splittedPrefName.length)));
          }
        }
      }

      if (values.length > value.length) {
        for (let i = value.length; i < values.length; i++) {
          values[i].remove();
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(`Invalid type for ${value}`);
    }

    if (write && (!store.getState().multiplayer.multiplayer || projectHost)) {
      const serializer = new XMLSerializer();
      const outXmlString = serializer.serializeToString(xml);

      // we add a new line before the <Preferences> tag
      const outXmlStringWithNewLine = outXmlString
        .replace('<Preferences ', '\n<Preferences ')
        .replace('></Preferences>', '>\n</Preferences>');

      // Add the xml header if it's not present
      let outXmlFinal = outXmlStringWithNewLine;
      if (!outXmlFinal.startsWith('<?xml version="1.0" encoding="utf-8"?>')) {
        outXmlFinal = `<?xml version="1.0" encoding="utf-8"?>${outXmlFinal}`;
      }

      if (fileHandle) await writeToFile(fileHandle, outXmlFinal);
    }

    this.newPreferencesSet[prefName] = value;

    if (store.getState().multiplayer.multiplayer && !stopSendingInstallXML) {
      const xmlSerializer = new XMLSerializer();
      const install = xmlSerializer.serializeToString(xml);

      const localInstallStr = localDoc.getText('install');
      localDoc.transact(() => {
        localInstallStr.delete(0, localInstallStr?.length);
        localInstallStr.insert(0, install);
      });

      syncYJSLocalToRemote();
    }

    if (truckSerial) {
      let truckPrefDoc = this.trucksPrefs.find((truckPref) => truckPref.serial === truckSerial)?.document;

      if (truckPrefDoc) {
        truckPrefDoc = xml;
      }
    } else {
      this.install = xml;
    }

    return [true, xml];
  }

  public static async saveYJSPreferences(): Promise<boolean> {
    const projectDirectoryHandle = await this.getDirectoryHandle();
    const prefDirectoryHandle = await projectDirectoryHandle?.getDirectoryHandle('PREF');
    const installXmlFileHandle = await prefDirectoryHandle?.getFileHandle('install.xml');
    const xml = this.install;

    if (!xml) return false;

    const serializer = new XMLSerializer();
    const outXmlString = serializer.serializeToString(xml);

    // we add a new line before the <Preferences> tag
    const outXmlStringWithNewLine = outXmlString
      .replace('<Preferences ', '\n<Preferences ')
      .replace('></Preferences>', '>\n</Preferences>');

    if (installXmlFileHandle) await writeToFile(installXmlFileHandle, outXmlStringWithNewLine);

    return true;
  }

  public static getPreferenceNamesInCategory(categoryName: string, serial?: string): string[] {
    if (!this.arePreferencesFullyLoaded()) return [];

    const splittedCategory = categoryName.split('/');
    let selector = '';
    for (let i = 0; i < splittedCategory.length; i++) {
      selector += `category[name=${splittedCategory[i]}] `;
    }

    if (serial) {
      const truck = this.trucksPrefs.filter((doc) => {
        return doc.serial === serial;
      })[0];
      if (!truck) return [];
      const modelName = truck.document.querySelector('[name=modelFileName]')?.textContent as string;
      const model = this.modelsPrefs.filter((doc) => {
        return doc.model === modelName;
      })[0];
      if (!model) return [];

      if (truck.document.querySelector(selector)) {
        const prefs: string[] = Array.from(truck.document.querySelector(selector)?.querySelectorAll('pref') || []).map(
          (pref) => pref.getAttribute('name') || ''
        );

        return prefs;
      } else if (model.document.querySelector(selector)) {
        const prefs: string[] = Array.from(model.document.querySelector(selector)?.querySelectorAll('pref') || []).map(
          (pref) => pref.getAttribute('name') || ''
        );

        return prefs;
      }

      return [];
    }

    const prefs: string[] = Array.from(this.install?.querySelector(selector)?.querySelectorAll('pref') || []).map(
      (pref) => pref.getAttribute('name') || ''
    );

    return prefs;
  }

  private static callbackWhenFullyLoaded(): void {
    // we save the directory handle for future use, such as when we want to save the circuit
    const projectName = getPreferenceValue('general/projectName') as string;

    // we start the auto-save backup feature
    this.startAutoSave();

    this.projectName = projectName;

    setTimeout(() => {
      window.dispatchEvent(new Event('projectLoaded'));
    }, 0);
  }

  public static startAutoSave(): void {
    if (this.autoSaveInterval) clearInterval(this.autoSaveInterval);
    const autoSaveCircuit = getSettings('autoSaveCircuit');
    if (autoSaveCircuit) {
      const autoSaveEvery = getSettings('autoSaveCircuitInterval') * 60 * 1000;
      this.autoSaveInterval = setInterval(this.autoSaveProject, autoSaveEvery, true);
    }
  }

  private static autoSaveProject(archive = true): void {
    saveProject(archive).catch((e) => {
      if (e instanceof DOMException && e.code === 18) {
        SnackbarUtils.warning(
          `Backup not saved, you need to save the project a first time in the session to start the backup saving.`
        );
        // eslint-disable-next-line no-console
        console.debug(e);
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to save the project', e);
      }
    });
  }

  public static arePreferencesFullyLoaded(): boolean {
    return this.areInstallPrefsLoaded && this.areModelsPrefsLoaded && this.areTrucksPrefsLoaded;
  }

  public static forcePreferencesStateAsLoaded(): void {
    this.areInstallPrefsLoaded = true;
    this.areModelsPrefsLoaded = true;
    this.areTrucksPrefsLoaded = true;
  }

  public static addNewTruckPreferences(serial: string, modelName: string, xmlPrefFileStr: string): void {
    this.trucks.push({
      ID: this.trucks.length,
      serial,
      modelName,
    });

    const xmlDoc = new DOMParser().parseFromString(xmlPrefFileStr, 'text/xml');

    this.trucksPrefs.push({
      serial,
      document: xmlDoc,
    });
  }

  public static addNewModelPreferences(modelName: string, xmlPrefFileStr: string): void {
    this.modelNames.push(modelName);

    const xmlDoc = new DOMParser().parseFromString(xmlPrefFileStr, 'text/xml');

    this.modelsPrefs.push({
      model: modelName,
      document: xmlDoc,
    });
  }

  public static async loadMapFile(mapFile?: File): Promise<
    | {
        mapDataTxt: string;
        mapNameTxt?: string;
        mapNameGeo?: string;
      }
    | undefined
  > {
    let mapNameGeo: string | undefined;
    let mapNameTxt: string | undefined;

    if (!mapFile) {
      if (!this.arePreferencesFullyLoaded())
        throw new Error('Cannot load map file with no argument if the preferences are not loaded');

      mapNameGeo = this.getPreferenceValue('localisation/mapFilePath') as string;
      mapNameTxt = mapNameGeo.replace('.geo', '.txt');

      const projectDirectoryHandle = this.directoryHandle as FileSystemDirectoryHandle;
      const mapDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('MAP');

      let mapFileFileHandle: FileSystemFileHandle | undefined;
      try {
        mapFileFileHandle = await mapDirectoryHandle.getFileHandle(mapNameTxt, {
          create: false,
        });
      } catch (e) {}

      mapFile = mapFileFileHandle ? await mapFileFileHandle.getFile() : undefined;

      if (!mapFile) {
        // eslint-disable-next-line no-console
        console.warn(`Lidar map file ${mapNameTxt} not found`);

        return;
      }
    }

    const mapDataTxt = await mapFile.text();

    return { mapDataTxt, mapNameGeo, mapNameTxt };
  }

  public static getCircuitName(): string {
    if (!this.arePreferencesFullyLoaded()) {
      if (!projectHost) return '';
      throw new Error('Cannot load circuit file with no argument if the preferences are not loaded');
    }

    const circuitName = (this.getPreferenceValue('general/circuitFileName') as string).replace('.xml', '.geojson');

    return circuitName;
  }

  public static getProjectName(): string {
    if (!this.arePreferencesFullyLoaded()) {
      if (!projectHost) return '';
      throw new Error('Cannot load circuit file with no argument if the preferences are not loaded');
    }

    const projectName = this.getPreferenceValue('general/projectName') as string;

    return projectName;
  }

  public static getAvailableCircuitsName(): string[] {
    if (!this.arePreferencesFullyLoaded()) {
      if (!projectHost) return [];
      throw new Error('Cannot load circuit file with no argument if the preferences are not loaded');
    }

    return this.availableCircuits || [];
  }

  public static async loadCircuitFile(circuitFile?: File): Promise<string> {
    if (!circuitFile) {
      if (!this.arePreferencesFullyLoaded())
        throw new Error('Cannot load circuit file with no argument if the preferences are not loaded');

      const circuitName = this.getCircuitName();

      const projectDirectoryHandle = this.directoryHandle as FileSystemDirectoryHandle;
      const circuitDirectoryHandle = await projectDirectoryHandle.getDirectoryHandle('Circuits');

      circuitFile = await (await circuitDirectoryHandle.getFileHandle(circuitName)).getFile();

      if (!circuitFile) {
        // eslint-disable-next-line no-console
        console.warn(`Circuit file ${circuitName} not found`);

        return '';
      }
    }

    return await circuitFile.text();
  }

  public static async changeDirectoryHandle(): Promise<FileSystemDirectoryHandle | void> {
    try {
      this.directoryHandle = await window.showDirectoryPicker({ mode: 'readwrite' });

      if (!projectHost) {
        if (this.directoryHandle.name !== this.projectName && this.projectName) {
          this.directoryHandle = await this.directoryHandle.getDirectoryHandle(this.projectName, { create: true });
        }
      }

      const recentProjects = getRecentProjects();

      const index = recentProjects.findIndex((project) => project.name === this.directoryHandle?.name);
      if (index > -1) {
        recentProjects.splice(index, 1);
      }

      recentProjects.unshift({ name: this.directoryHandle.name, lastOpen: new Date().toUTCString() });

      if (recentProjects.length > 5) {
        recentProjects.length = 5;
      }

      localStorage.setItem('recentProjects', JSON.stringify(recentProjects));
      const dbPromise = indexedDB.open('projects');

      dbPromise.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (db.objectStoreNames.contains('projects')) return;

        db.createObjectStore('projects');
      };

      dbPromise.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        if (!db.objectStoreNames.contains('projects')) return;

        const transaction = db.transaction('projects', 'readwrite');
        const objectStore = transaction.objectStore('projects');

        objectStore.put(this.directoryHandle, this.directoryHandle?.name);
        db.close();
      };

      return this.directoryHandle;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('No directory handle found');

      if (e instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(e);

        if (e instanceof TypeError || (e instanceof DOMException && e.name === 'SecurityError')) {
          SnackbarUtils.error(
            `Access to the disk system denied. This is likely due to an unsupported browser. Please retry with another browser.`
          );
        }
      }

      return;
    }
  }

  /**
   * Returns the project folder handle
   * @returns the project folder handle
   */
  public static async getDirectoryHandle(): Promise<FileSystemDirectoryHandle | undefined> {
    if (!this.directoryHandle) {
      try {
        this.directoryHandle = (await this.changeDirectoryHandle()) as FileSystemDirectoryHandle;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('No directory handle found');

        if (e instanceof Error) {
          // eslint-disable-next-line no-console
          console.error(e);

          if (e instanceof TypeError) {
            SnackbarUtils.error(
              `Access to the disk system denied. This is likely due to an unsupported browser. Please retry with another browser.`
            );
          }
        }

        return;
      }
    }

    return this.directoryHandle;
  }

  private static generateTrucks(): void {
    if (!this.trucksPrefs.length) return;

    const serialsTmp = (() => {
      try {
        return this.getPreferenceValue('general/trucksSerial');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
        SnackbarUtils.error(`Error while loading the trucks serials, the preference general/trucksSerial is missing`);

        return [];
      }
    })();
    const serials = Array.isArray(serialsTmp) ? serialsTmp : [serialsTmp];
    const trucks = serials.map((serial, index) => {
      const modelName = (() => {
        try {
          return this.getPreferenceValue('general/modelFileName', serial) as string;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
          SnackbarUtils.error(`Error while loading the model file name for the truck ${serial}`);

          return '';
        }
      })();

      const truck: Truck = {
        ID: index + 1,
        serial,
        modelName,
      };

      return truck;
    });

    this.trucks = trucks;
  }

  public static getTrucks(): Truck[] {
    if (this.trucks.length) return this.trucks;
    if (!this.trucksPrefs.length) return [];

    this.generateTrucks();

    return this.trucks;
  }

  public static getSerialNames(): string[] {
    if (this.trucks.length) return this.trucks.map((truck) => truck.serial);
    if (!this.trucksPrefs.length) return [];

    this.generateTrucks();

    return this.trucks.map((truck) => truck.serial);
  }

  public static getModelNames(): string[] {
    if (this.modelNames.length) return this.modelNames;
    if (!this.modelsPrefs.length) return [];

    const serials = this.getSerialNames();
    const modelNames: Set<string> = new Set();

    serials.forEach((serial) => {
      try {
        modelNames.add(this.getPreferenceValue('general/modelFileName', serial) as string);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);

        SnackbarUtils.error(`Error while loading the model file name for the truck ${serial}`);
      }
    });

    this.modelNames = Array.from(modelNames);

    return this.modelNames;
  }

  public static async getFileByPath(path: string): Promise<File | null> {
    try {
      if (!this.directoryHandle) return null;

      const splittedPath = path.split('/');
      const fileName = splittedPath[splittedPath.length - 1];

      const directoryPath = splittedPath.slice(0, splittedPath.length - 1);

      let directoryHandle = this.directoryHandle;
      if (directoryPath.length) {
        for (let i = 0; i < directoryPath.length; i++) {
          directoryHandle = await directoryHandle.getDirectoryHandle(directoryPath[i]);
        }
      }

      const fileHandle = await directoryHandle.getFileHandle(fileName);

      const file = await fileHandle.getFile();

      return file;
    } catch (e) {
      return null;
    }
  }

  public static async moveFile(oldPath: string, newPath: string): Promise<boolean> {
    if (!this.directoryHandle) return false;

    try {
      const oldFile = await this.getFileByPath(oldPath);
      if (!oldFile) return false;

      const newFile = await this.directoryHandle.getFileHandle(newPath, { create: true });
      await writeToFile(newFile, await oldFile.text());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while writing the file', e);

      return false;
    }

    await this.directoryHandle.removeEntry(oldPath);

    return true;
  }

  public static getNEW4XCoreVersion(): number | undefined {
    return this.ProjectNEWVersion;
  }

  public static getSDKVersion(): string | null | undefined {
    if (!this.arePreferencesFullyLoaded()) return null;

    return this.install?.querySelector('Preferences')?.getAttribute('sdk');
  }

  /**
   * Generate xml string from preferences files
   * @returns the generated xml strings
   */
  public static getPreferencesXmlFiles(): PreferencesXmlFiles | undefined {
    const xmlSerializer = new XMLSerializer();

    if (
      !this.install ||
      !this.installDictionaries ||
      !this.modelsPrefs ||
      !this.modelsDictionaries ||
      !this.trucksPrefs ||
      !this.trucksDictionaries
    ) {
      return undefined;
    }

    const xmls: PreferencesXmlFiles = {
      install: xmlSerializer.serializeToString(this.install).replaceAll('\n', ''),
      installDictionaries: this.installDictionaries.map((installDictionary) =>
        xmlSerializer.serializeToString(installDictionary).replaceAll('\n', '')
      ),
      models: this.modelsPrefs.map((modelDoc) => ({
        model: modelDoc.model,
        xml: xmlSerializer.serializeToString(modelDoc.document).replaceAll('\n', ''),
      })),
      modelsDictionaries: this.modelsDictionaries.map((modelsDictionary) =>
        xmlSerializer.serializeToString(modelsDictionary).replaceAll('\n', '')
      ),
      trucks: this.trucksPrefs.map((truckDoc) => ({
        serial: truckDoc.serial,
        xml: xmlSerializer.serializeToString(truckDoc.document).replaceAll('\n', ''),
      })),
      trucksDictionaries: this.trucksDictionaries.map((trucksDictionary) =>
        xmlSerializer.serializeToString(trucksDictionary).replaceAll('\n', '')
      ),
    };

    return xmls;
  }

  /**
   * Generate xml string from preferences files
   * @returns the generated xml strings
   */
  public static getPreferencesXmlFilesWithIndentation(): PreferencesXmlFiles | undefined {
    const xmlSerializer = new XMLSerializer();

    if (
      !this.install ||
      !this.installDictionaries ||
      !this.modelsPrefs ||
      !this.modelsDictionaries ||
      !this.trucksPrefs ||
      !this.trucksDictionaries
    ) {
      return undefined;
    }

    const xmls: PreferencesXmlFiles = {
      install: xmlSerializer.serializeToString(this.install),
      installDictionaries: this.installDictionaries.map((installDictionary) =>
        xmlSerializer.serializeToString(installDictionary)
      ),
      models: this.modelsPrefs.map((modelDoc) => ({
        model: modelDoc.model,
        xml: xmlSerializer.serializeToString(modelDoc.document),
      })),
      modelsDictionaries: this.modelsDictionaries.map((modelsDictionary) =>
        xmlSerializer.serializeToString(modelsDictionary)
      ),
      trucks: this.trucksPrefs.map((truckDoc) => ({
        serial: truckDoc.serial,
        xml: xmlSerializer.serializeToString(truckDoc.document),
      })),
      trucksDictionaries: this.trucksDictionaries.map((trucksDictionary) =>
        xmlSerializer.serializeToString(trucksDictionary)
      ),
    };

    return xmls;
  }

  /**
   * Converts a robot's serial number to its corresponding ID.
   * @param {string} serial - The serial number of the robot.
   * @returns {number} The ID of the robot.
   * @throws {Error} If no truck with the provided serial number is found.
   */
  public static robotSerialToId(serial: string): number | undefined {
    const trucks = PreferencesService.getTrucks();
    const truck = trucks.find((truck) => truck.serial === serial);

    return truck?.ID;
  }
}

export async function writeToFile(fileHandle: FileSystemFileHandle, text: BufferSource | Blob | string): Promise<void> {
  let writable: FileSystemWritableFileStream;
  try {
    writable = await fileHandle.createWritable();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating writable file', e);
    throw e;
  }

  try {
    await writable.write(text);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error writing to file', e);
    throw e;
  }

  try {
    await writable.close();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error closing file', e);
  }
}

export async function loadFileAsUint8Array(blob: File): Promise<Uint8Array> {
  const reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  const content: ArrayBuffer = await new Promise((resolve, reject) => {
    reader.onload = (e) => resolve(reader.result as ArrayBuffer);
    reader.onerror = (e) => reject(reader.error);
  });

  return new Uint8Array(content);
}

export async function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  const content: string = await new Promise((resolve, reject) => {
    reader.onload = (e) => resolve(reader.result as string);
    reader.onerror = (e) => reject(reader.error);
  });

  return content;
}

export async function convertBlobUrlToUInt8Array(blobUrl: string): Promise<Uint8Array> {
  const response = await fetch(blobUrl);
  const arrayBuffer = await response.arrayBuffer();

  return new Uint8Array(arrayBuffer);
}

export function parseXmlString(xml: string): Document {
  const parser = new DOMParser();
  const dom = parser.parseFromString(xml, 'application/xml');

  return dom;
}

export function getPreferenceValue(prefName: string, serial?: string): string | string[] {
  return PreferencesService.getPreferenceValue(prefName, serial);
}

/**
 * Get the coreVersion file from the project
 * @returns the coreVersion file
 */
async function getCoreVersionFile(): Promise<File | undefined> {
  const projectDirectoryHandle = await PreferencesService.getDirectoryHandle();
  if (!projectDirectoryHandle) return;

  const fileName = 'coreVersion';
  try {
    const coreVersionFileAtRoot = await (await projectDirectoryHandle.getFileHandle(fileName)).getFile();
    if (coreVersionFileAtRoot) return coreVersionFileAtRoot;
  } catch (e) {
    if (e instanceof DOMException && e.name === 'NotFoundError') {
      // eslint-disable-next-line no-console
      console.warn(`File ${fileName} not found at the root of the project`);

      SnackbarUtils.warning('The CORE version has not been detected by Road Editor because the file is missing.');
    }
  }
}
