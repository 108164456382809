import { Button, Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import type { Flow, Station } from 'flows/flows';
import { removeFlow, setFlow } from 'flows/flows';
import { isEqual } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useMemo } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { FlowConfigDisplay, maximumFlowNameLength } from './flow';
import { ListStationsOfFlow } from './list-stations-flow';
import { MaximumTaskTimeTextfield } from './maximum-task-time-textfield';
import { ObjectiveTextfield } from './objective-textfield';
import { PalletsPerTaskTexfield } from './pallets-per-task-texfield';
import { RenameFlow } from './rename-flow';

interface FlowFormProps {
  flow: Flow;
  flows: Flow[];
  flowConfigDisplay: FlowConfigDisplay;
  setFlowConfigDisplay: (value: FlowConfigDisplay) => void;
  setSelectedTab: (tabToSelect: string) => void;
}
export function FlowForm(props: FlowFormProps): JSX.Element {
  const { flow, flows, flowConfigDisplay, setFlowConfigDisplay, setSelectedTab } = props;
  const stations: (Station | undefined)[] = useAppSelector((state) => state.flows.stations);

  const formerState = useMemo(
    () => structuredClone(store.getState().flows.flows.find((f) => f.id === flow.id)),
    [flow.id]
  );
  const confirm = useConfirm();

  const dispatch = useAppDispatch();

  const stationsOfSelectedFlow = useMemo(() => {
    const selectedStations: Station[] = [];

    if (flow) {
      for (let i = 0; i < flow.stations.length; i++) {
        stations.forEach((st) => {
          if (st && flow.stations[i].id.includes(st.id)) {
            selectedStations.push(st);
          }
        });
      }
    }

    return [...new Set(selectedStations)];
  }, [flow, stations]);

  const isInvalidName = useCallback(
    (newName: string | undefined) => {
      const nameWihtoutBlank = newName?.trimStart().trimEnd();
      if (nameWihtoutBlank?.length && nameWihtoutBlank.length > maximumFlowNameLength) {
        return true;
      }

      const existingFlow = flows.find((f) => f.name === nameWihtoutBlank && f.id !== flow.id);
      if (!nameWihtoutBlank || existingFlow) {
        return true;
      }

      return false;
    },
    [flow.id, flows]
  );

  // Add Flow
  const handleCreateFlow = useCallback(() => {
    SnackbarUtils.success(`Flow "${flow?.name}" created`);

    setFlowConfigDisplay(FlowConfigDisplay.Default);
  }, [flow?.name, setFlowConfigDisplay]);

  const handleCancelAdding = useCallback(() => {
    if (flow) dispatch(removeFlow(flow.id));

    setFlowConfigDisplay(FlowConfigDisplay.Default);
  }, [dispatch, flow, setFlowConfigDisplay]);

  // Edit Flow
  const handleSaveFlow = useCallback(() => {
    SnackbarUtils.success(`Flow "${flow?.name}" Edited`);

    setFlowConfigDisplay(FlowConfigDisplay.Default);
  }, [flow?.name, setFlowConfigDisplay]);

  const handleCancelEdition = useCallback(() => {
    const hasChanges = isEqual(formerState, flow);

    if (!hasChanges) {
      confirm({
        title: `Keep ${flow.name} unchanged?`,
        confirmationText: 'Keep it unchanged',
        cancellationText: 'Let me edit it',
        cancellationButtonProps: {
          color: 'error',
          autoFocus: true,
        },
      })
        .then(() => {
          if (formerState) dispatch(setFlow(formerState));
          setFlowConfigDisplay(FlowConfigDisplay.Default);
        })
        .catch(() => undefined);
    } else {
      setFlowConfigDisplay(FlowConfigDisplay.Default);
    }
  }, [confirm, dispatch, flow, formerState, setFlowConfigDisplay]);

  return (
    <div>
      <Stack direction={'row'} spacing={1} sx={{ mt: 3 }}>
        <RenameFlow
          flow={flow}
          formerState={flowConfigDisplay === FlowConfigDisplay.Edition ? formerState : undefined}
          isInvalidName={isInvalidName}
        />
      </Stack>

      <>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            marginTop: theme.spacing(2),
          }}
        >
          <ObjectiveTextfield selectedFlow={flow} selectedFlowId={flow.id} />

          <MaximumTaskTimeTextfield selectedFlow={flow} selectedFlowId={flow.id} />
        </Stack>
        <Box
          component={'div'}
          sx={{
            marginTop: theme.spacing(2),
          }}
        >
          <PalletsPerTaskTexfield selectedFlow={flow} selectedFlowId={flow.id} />
        </Box>
      </>

      <Border>
        <ListStationsOfFlow
          flowConfigDisplay={flowConfigDisplay}
          key={flow.id}
          stations={stationsOfSelectedFlow ?? []}
          flow={flow}
          setSelectedTab={setSelectedTab}
        />
      </Border>
      <Divider variant="middle" sx={{ mt: 2, mb: 0.5 }} />
      {flowConfigDisplay === FlowConfigDisplay.Creation && (
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="text" color="error" onClick={() => handleCancelAdding()}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleCreateFlow()} disabled={isInvalidName(flow.name)}>
            Create
          </Button>
        </Stack>
      )}
      {flowConfigDisplay === FlowConfigDisplay.Edition && (
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="text" color="error" onClick={handleCancelEdition}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveFlow} disabled={isInvalidName(flow.name)}>
            Save
          </Button>
        </Stack>
      )}
    </div>
  );
}
