import type { CustomStep } from 'flows/custom-steps.model';
import type { Flow, Station } from 'flows/flows';
import type { Feature, FeatureCollection, GeoJsonProperties, LineString, Point, Polygon, Position } from 'geojson';
import type { LayersDataContainer } from 'reducers/circuit/state';
import type { FilterState } from 'reducers/local/filters.reducer';
import type { RobotBattery } from 'simulation/simulation';
import type { CantonsDict } from 'traffic/traffic';
import { z } from 'zod';
import type { MapImageScalingSourceData } from './maps';
import type { Trigger } from './simulation';
import type { StockDBConfiguration } from './stockDB-configuration';

/**
 * @see {isShapeType} ts-auto-guard:type-guard
 */
export enum ShapeTypes {
  PointShape = 'POINT',
  ZoneShape = 'ZONE',
  SegmentShape = 'SEGMENT',
  MeasurerShape = 'MEASURER',
  TurnShape = 'TURN',
  StockZoneShape = 'STOCK',
  RackShape = 'RACK',
  DeviceShape = 'DEVICE',
  NoteShape = 'NOTE',
}

export enum SlotTypes {
  Slot = 'SLOT',
  StockLine = 'STOCKLINE',
}

/**
 * Object used to store the selected shapes data.
 * Internal to Road Editor, not directly exported in the circuit file
 */
export interface Circuit {
  zones: CircuitZone[];
  racks: CircuitRack[];
  points: CircuitPoint[];
  segments: CircuitSegment[];
  measurers: CircuitMeasurer[];
  turns: CircuitTurn[];
  stockZones: CircuitStockZone[];
  devices: CircuitDevice[];
  notes: CircuitNote[];
}

interface DrawingProperties {
  hidden?: boolean;
}

/** {link ZoneProperties} */
export interface CircuitZone extends Feature<Polygon, ZoneProperties>, DrawingProperties {}
/** {link StockZoneProperties} */
export interface CircuitStockZone extends Feature<Polygon, StockZoneProperties>, DrawingProperties {
  isEditing?: boolean;
}
/** {link InterestPointProperties} */
export interface CircuitPoint extends Feature<Point, InterestPointProperties>, DrawingProperties {}
/** {link SegmentProperties} */
export interface CircuitSegment extends Feature<LineString, SegmentProperties>, DrawingProperties {}
/** {link MeasurerProperties} */
export interface CircuitMeasurer extends Feature<LineString, MeasurerProperties>, DrawingProperties {}
/** {link TurnProperties} */
export interface CircuitTurn extends Feature<LineString, TurnProperties>, DrawingProperties {}
/** {link RackProperties} */
export interface CircuitRack extends Feature<Polygon, RackProperties>, DrawingProperties {}
/** {link DeviceProperties} */
export interface CircuitDevice extends Feature<Point, DeviceProperties>, DrawingProperties {}

export interface CircuitNote extends Feature<Point, NoteProperties>, DrawingProperties {}

export type CircuitCellTemplate = RackCellTemplate;

/**
 * Any circuit shape
 *
 * @see {isCircuitShapeSlow} ts-auto-guard:type-guard
 */
export type CircuitShape =
  | CircuitZone
  | CircuitStockZone
  | CircuitPoint
  | CircuitSegment
  | CircuitMeasurer
  | CircuitTurn
  | CircuitRack
  | CircuitDevice
  | CircuitNote;
export type CircuitShapes = CircuitShape[];
export type ShapeProperties =
  | ZoneProperties
  | StockZoneProperties
  | InterestPointProperties
  | SegmentProperties
  | MeasurerProperties
  | CircuitTurn
  | RackProperties
  | DeviceProperties
  | NoteProperties;

export enum Intersection {
  PointIntersection = 'POINT',
  GabaritIntersection = 'GABARIT',
}

export const ZoneRuleNameSchema = z.enum([
  'LimitSpeed',
  'NoBeepInside',
  'HornEntering',
  'HornLeaving',
  'HornInside',
  'StopEntering',
  'StopLeaving',
  'NoParking',
  'RoofHeight',
  'CurtainInhibitedArea',
  'Door',
  'BlackHole',
  'NoHardSafetyInside',
  'BatteryCharger',
  'LimitAgvCount',
  'SafetyManualAck',
  'TrafficByID',
  'FloorName',
  'ElevatorName',
]);
export type ZoneRuleName = z.infer<typeof ZoneRuleNameSchema>;

export function isZoneRuleName(value: unknown): value is ZoneRuleName {
  return ZoneRuleNameSchema.safeParse(value).success;
}

/**
 * Broadcast property of zones, available in 4.15.1
 * @link https://redmine.balyo.com/issues/53658
 */
export const BroadcastSchema = z.enum(['Local', 'External', 'Both']);
export type Broadcast = z.infer<typeof BroadcastSchema>;

export const broadcastDefaultValue: Broadcast = 'Local';

export function isBroadcast(value: unknown): value is Broadcast {
  return BroadcastSchema.safeParse(value).success;
}

/**
 * List of rules that can be applied to a zone
 * - RuleName = name of the rule
 * - number (optional) = parameter of the rule
 */
export type ZoneRule = [ZoneRuleName, (number | string)?][];

export interface ZoneProperties {
  /** = "ZONE" */
  type: ShapeTypes.ZoneShape;
  /** id of the layer in which the shape is */
  layerId: string;
  /** display name of the shape */
  name: string;
  /** rules applied to the zone */
  rules: ZoneRule;
  /** collision computation between the zone and the robot, possible values = "POINT" | "GABARIT" */
  intersectionType: Intersection;
  /** user comment(s) */
  comments?: string[];
  /** is the shape locked? */
  locked?: boolean;
  /** int, display priority, the greater the more on the front */
  prio: number;
  /** Data about the door, only applied when the zone is a door */
  door?: DoorSettings;
  /** Id of the conveyor linked to it */
  conveyor?: string;
  /** displayHMI is for the zones will appear in the circuit displayed on the Fleet Manager Interface */
  displayHMI?: boolean;

  /** enter event to trigger in the robot when the robot enters the zone */
  enterEventName?: string;
  /** exit event to trigger in the robot when the robot exits the zone */
  exitEventName?: string;

  /** broadcast property of the zone */
  broadcast?: Broadcast;

  /** Some properties about the zone when it is an aisle between two racks */
  aisle?: {
    isAisle: true;
    /** The id of the first rack from which the aisle has been generated */
    rack0: string;
    /** The id of the second rack from which the aisle has been generated */
    rack1: string;
  };
}

export interface DeviceInDoor {
  /** The device id */
  deviceId: string;
  /** The pin id, from 0 to the maximum number of pins of the device */
  pinId: number;
  /**
   * Specify when the door is open depending of the device state value
   * 0 = OFF
   * 1 = ON
   */
  openValue: 0 | 1;
}

export interface DoorSettings {
  /**
   * Whether the zone is a door or not
   */
  enabled: boolean;
  /**
   * The door can be connected to devices, the SDK or NEW4X will read this parameter to
   * connected the door to the right devices
   *
   * If at least one device is defined, the door will be closed unless we meet all the conditions
   *
   * Doors can be opened and closed by other things than devices (WMS, etc.) by the SDK or NEW4X
   */
  devices: DeviceInDoor[];
  /**
   * Distance from the zone from which the robot ask the robot manager to open the door (in meters)
   */
  dAsk: number;
  /**
   * Distance from the zone from which the robot stops if the door is closed (in meters)
   */
  dStop: number;
}

export type TrafficType = 'kernel' | 'deadend' | 'deadend-exit' | 'deadend-entry' | 'auto';
/**
 * Convert Road Editor TrafficType to Kiwi ForceRule
 */
export const trafficTypeMap = {
  deadend: 'DeadEnd',
  'deadend-entry': 'DeadEnd-Entry',
  'deadend-exit': 'DeadEnd-Exit',
  kernel: 'Kernel',
};

export interface CircuitPortion {
  /** unique id of the portion */
  id: string;
  /** points that defines the portion ([x, y, cap][]) */
  points: Position[];
  /** do we force a special traffic rule on the portion?, possible values: 'kernel' | 'deadend' | 'deadend-exit' | 'deadend-entry' | 'auto' */
  trafficType?: TrafficType;

  /** input portions (ids) */
  inStart?: string[];
  outStart?: string[];
  /** output portions (ids) */
  inEnd?: string[];
  outEnd?: string[];

  /** if the start point of the portion is linked to a turn, here's its id */
  turnIdStart?: string;
  /** if the end point of the portion is linked to a turn, here's its id */
  turnIdEnd?: string;

  /** if the portion is linked to a stockzone, here's its id */
  stockZoneId?: string;
}

export type SegmentAisleDirection = 'left' | 'right' | 'both';

export interface SegmentProperties {
  /** "SEGMENT" */
  type: ShapeTypes.SegmentShape; // "SEGMENT"
  /** id of the layer in which the shape is */
  layerId: string;
  /** display name of the segment */
  name: string;
  /** true if the robot can take the segment in both directions (always false for now) */
  twoWay: boolean;
  /** user comments */
  comments?: string[];
  /** is the shape locked? */
  locked?: boolean;
  /** list of the portions of the segments */
  portions: CircuitPortion[];

  /** id of the stock line if the segment is the extended length of a stock line */
  stockLine?: string;
  /** id of the rack if the segment is the extended length of a rack */
  rack?: string;
  /** id of the rack column if the segment is the extended length of a rack */
  rackColumn?: string;
  /** is the segment and portions wire guided? (for VNAs) */
  wireGuided?: boolean;
  /** if the user wants to display the truck gabarit along the segment **/
  gabarit?: GabaritProperties;

  /** integer, display priority, the greater the more on the front */
  prio: number;

  aisle?: {
    /** Is the segment connected to an aisle? */
    aisleId: string;
    /** Offset between the middle of the aisle and the position of the segment [m] */
    offset: number;
    /** Direction of the segment in the aisle */
    direction: SegmentAisleDirection;
  };
}

export interface MeasurerLinkedShape {
  /** id of the shape it is linked to */
  id: string;
  /** type of the shape, such as 'POINT' | 'SEGMENT' | 'STOCK' | etc. */
  type: ShapeTypes;
}

export enum MeasurementType {
  MinimumDistance = 'MinimumDistance',
  Center2Center = 'CenterToCenter',
}

export interface MeasurerProperties {
  /** = "MEASURER" */
  type: ShapeTypes.MeasurerShape;
  /** id of the layer in which the shape is */
  layerId: string;
  /** display name of the shape */
  name: string;
  /** is the shape locked? */
  locked?: boolean;
  /** is the length locked? */
  lockedLength?: boolean;
  /* should we display the length of the measurer in the playground? */
  showLength?: boolean;
  /** minimum distance or center to center */
  measurementType: MeasurementType;
  /** if the first point of the measurer is attached, it is a link to the attached shape */
  link0?: MeasurerLinkedShape;
  /** if the second point of the measurer is attached, it is a link to the attached shape */
  link1?: MeasurerLinkedShape;
  /** int, display priority, the greater the more on the front */
  prio: number;
  /** measurer's infinite length property */
  guide?: boolean;
}

export enum PointOfInterestTypes {
  TaxiPoint = 'Taxi Point',
  InitPoint = 'Init Point',
  DestinationPoint = 'Destination Point',
  UnhitchPoint = 'Unhitch Point',
  PickPoint = 'Pick Point',
}

export interface InterestPointProperties {
  /** "POINT" */
  type: ShapeTypes.PointShape;
  /** display name of the point */
  name: string;
  /** layer id in which the shape is contained */
  layerId: string;
  /** angle in degree */
  orientation: number;
  /** if the point is a taxi point */
  isTaxi: boolean;
  /** if the point is an initialization point */
  isInit: boolean;
  /** if the point is a battery point (i.e. docking station for LTO/TTPL robots or close to a table change for a Lead Acid robot) */
  isBattery: boolean;
  /** if the point is a destination point */
  isTeleportation: boolean;
  /** battery type if the point is a battery point */
  batteryType?: string;
  /** height of the point, always = 0 meters */
  height: number;
  /** link to the segment if the point is attached to a segment */
  segment?: SegmentDataInPoint;
  /** is the shape locked? */
  locked?: boolean;
  /** minimum initialization score, for init points */
  minimumInitScore?: number;
  /** whether or not we want to display the robot gabarit on the point */
  gabarit?: GabaritProperties;
  /** int, display priority, the greater the more on the front */
  prio: number;
  /**floor level */
  floorLevel?: string;
  /**elevator name */
  elevatorName?: string;
}

export interface SegmentDataInPoint {
  /** id of the segment the point is connected to */
  id: string;
  /** in [0, 1], position of the point on the segment, from the start point */
  position: number;
}

export interface GabaritProperties {
  /** should we display the gabarit */
  display: boolean;
  /** robot model name of the gabarit */
  modelName: string;
  /** name of the gabarit for this robot model (depending of the pref), for example it could be Fempty, Fcarry for safety gen 1, or Safety Gen 2 gabarits */
  type: string;
}

export interface TurnProperties {
  /** "TURN" */
  type: ShapeTypes.TurnShape;
  /** id of the layer the shape is contained */
  layerId: string;
  /** id of source segment of the turn */
  originId: string | undefined;
  /** id of the segment destination of the turn */
  destinationId: string | undefined;
  turnType: 'Normal' | 'StopBeforeTurn' | 'Tesseract';
  /** do we force a special traffic rule on the turn?, possible values: 'kernel' | 'deadend' | 'auto' */
  trafficType?: TrafficType;
  drivenBy?: 'Radius' | 'StartPoint' | 'StraightLine' | 'LineChange';
  /** radius in meters */
  radius?: number;
  /** [0, 1], represents the position of the origin on the origin segment (colinearity coefficient, i.e. k in u = kv) */
  positionFactorOrigin?: number;
  /** [0, 1], represents the position of the origin on the origin segment (colinearity coefficient, i.e. k in u = kv) */
  positionFactorDest?: number;
  /** properties associated with each point */
  coordinateProperties: {
    /** in degrees, cap of every point of the geometry.coordinates of the turn object */
    cap: number[];
  };
  /** max overshoot of the turn, >0, for normal turns */
  maxOvershoot?: number;
  /** display name of the turn */
  name?: string;
  /** If the user wants to display the gabarit of the turn, the associated data is stored in this object */
  gabarit?: GabaritProperties;
  /** int, display priority, the greater the more on the front */
  prio: number;
  /**
   * for turns connected to an extended length
   * it change the turn property (the turn is straight (like a segment) for startPointOffset meters until starting to actually turn)
   * [m]
   * */
  startPointOffset?: number;
}

/**
 * In theory a feature cuollection cannot have properties, but for our circuit we will add this property to the feature collection
 *
 * @see {isFeatureCollectionWithProperties} ts-auto-guard:type-guard
 */
export interface FeatureCollectionWithProperties extends FeatureCollection {
  properties: GeoJsonProperties;
}

export type ShapesCount = {
  [key in ShapeTypes]: number;
};

export interface MapImageData {
  /** name of the file of the image */
  name: string;
  /** height of the map image after scaling */
  height?: number;
  /** offset to position it on the playground */
  x: number;
  /** offset to position it on the playground */
  y: number;
  /** data entered by the user about the scaling, to help him to rescale  */
  scaling?: MapImageScalingSourceData;
  /** Original width of the image */
  originalWidth: number;
  /** Original height of the image */
  originalHeight: number;
}

/**
 * A GeoJSON FeatureCollection with properties
 * It cointains three properties:
 * - type: the type of the GeoJSON object (always "FeatureCollection")
 * - features: an array that contains all the shapes (such as segments, turns, points, etc.) of the circuit
 * - properties: an object that contains the properties of the circuit
 *
 * @see {isGeoJsonCircuitNotExtended} ts-auto-guard:type-guard
 */
export interface GeoJsonCircuit {
  /** 'FeatureCollection' */
  type: 'FeatureCollection';
  properties: {
    /** The version and description of each circuit while we save the file to GeoJSON or xml */
    circuitDescription: string;
    circuitVersion: string;
    /** The version of Road Editor used to generate the file */
    roadEditorVersion: string;
    /** Name of the client project */
    projectName: string;
    /** Hash (sha1) of the features  */
    sha1?: string;
    /** Comment */
    thisFile?: string;
    /** Name or email of the user who created the file */
    author?: string;
    /** next number id available to generate an id */
    nextFreeId: number;
    /** array of next number id available to generate an id online */
    nextFreeIdArray?: Array<number>;
    /** Data about the layers of the circuit */
    layers: LayersDataContainer;
    /** Data about the map image (layout image) of the circuit
     * ! DEPRECATED*/
    mapImageData?: MapImageData;
    /** Data about the map images (layout images) of the circuit */
    mapImageArray?: Array<MapImageData>;
    /** Data about the map obstacle (foreground lidar) of the circuit */
    mapObstacleData?: {
      /** name of the file of the map obstacle */
      name: string;
    };
    /** Indicate if some filters are enabled */
    filters?: FilterState;
    /** Cell templates of the project */
    cellTemplates?: Record<string, RackCellTemplate>;
    /** the number of shapes of each type in the circuit */
    shapesCount: ShapesCount;
    /**
     * Does Road Editor manage the devices and write/read the related preferences?
     * Default value (if undefined) = true
     */
    enableDevicePrefManagement?: boolean;
    /**
     * List of the available stations
     */
    stations?: Stations;
    /**
     * List of the available flows
     */
    flows?: Flow[];
    /**
     * List of the available triggers
     */
    triggers?: Trigger[];

    /**
     * Scheduler configuration used
     */
    schedulerConfiguration?: string;

    simulationParameters?: {
      /** Max duration of the simulation (auto-stop), 0 = infinite [s] */
      maxDuration?: number;
      /** The new robot battery level define by the user */
      robotsBatteryLevel?: RobotBattery[];
      /** Custom Steps that the user can define (special pick/drops with wait times or forks movement) */
      customSteps?: CustomStep[];
      /** whether the robots lose battery level and go to charge */
      enableCharging?: boolean;
    };
    /**
     * The StockDB configuration
     * @link https://redmine.balyo.com/issues/49288
     */
    stockDBConfiguration: StockDBConfiguration;
  };
  features: LayerFeatureCollection[];
}

/**
 * A GeoJSON FeatureCollection that contains all the shapes of the layer
 *
 * @see {isLayerFeatureCollection} ts-auto-guard:type-guard
 *  */
export interface LayerFeatureCollection extends FeatureCollectionWithProperties {
  /** id of the layer */
  id: string;
  /** all the shapes of the layer */
  features: CircuitShape[];
  /** properties of the layer, please note that there's also data about the layers in the properties of the circuit file (at the root) */
  properties: {
    shapesCount: ShapesCount;
    name: string;
  };
}
export interface Position3D {
  /** x position (m) */
  x: number;
  /** y positon (m) */
  y: number;
  /** z position (m) */
  z: number;
  /** Angle of the position (degrees) */
  cap: number;
}

export interface Size3D {
  /** Width of the object (m) */
  width: number;
  /** Length of the object (m) */
  length: number;
  /** Height of the object (m) */
  height?: number;
}

/**
 * Define how the pallet is positioned in a slot
 * No referenceZ because gravity
 */
export interface PalletPosition {
  /** = 0, in meters */
  offsetX: number; // = 0
  /** = 0, in meters */
  offsetY: number; // = 0
  /** = 0, in meters */
  offsetZ?: number;
  /** reference in X of how the pallet is positioned, possible values: ['Front Edge', 'Rear Edge', 'Center'] */
  referenceX: ReferenceMethodX;
  /** reference in X of how the pallet is positioned, possible values: ['Left Edge', 'Right Edge', 'Center'] */
  referenceY: ReferenceMethodY;
}

/**
 * Define the properties of a slot
 */
export interface Slot {
  /** id of the slot */
  id: string;
  /** display name of the slot */
  name: string;
  /** how the slot is positioned? (cm) */
  slotPosition: Position3D;
  /* id of the parent of the slot */
  parentId?: string;
  /* how precise has the pallet in the slot to be positioned? (meters) */
  tolerancePosition: Position3D;
  /** argument for the cam3d */
  freespaceMethod?: number;
  /** accepted pallet types of the slot */
  palletTypes?: string[];
  /** pallet size (meters) */
  palletSize: Size3D;
  /** computed final position of the pallet */
  palletPosition: PalletPosition;
  /** Size of the slot (meters) */
  slotSize: Size3D;
  /** computed geometry of the slot, for instant display (cm) */
  geometry: Position[];
  /** is the slot disabled? */
  disabled?: boolean;
}

/**
 * Define a slot line
 */
export interface SlotArray {
  /** id of the slotarray */
  id: string;
  /** display name of the slotarray (slotline) */
  name: string;
  /** has the name been edited by the user? */
  userEditedName: boolean;
  /* slots contained in the slots array */
  slots: Slot[];
}

export enum PatternTypes {
  uniformDistribution = 'uniformDistribution',
  repetitivePattern = 'repetitivePattern',
  relatedDistribution = 'relatedDistribution',
  customDistribution = 'customDistribution',
}

//  1 --> furthest
//  2 --> nearest
//  3 --> equality
//  4 --> scanlist sequence: first item of the scanlist comes first when possible
//  5 --> scanlist sequence: last item of the scanlist comes first when possible
//  6 --> custom case, to be define in missionCallbacks
export type fillOrEmptyStrategy = 1 | 2 | 3 | 4 | 5 | 6;

/** Properties of a stock zone */
export interface StockZoneProperties {
  /** "STOCK" */
  type: ShapeTypes.StockZoneShape;
  /** display name of the stock zone */
  name: string;
  /** stock lines contained in the stock zones */
  slots: SlotArray[];
  /** id of the layer in which the layer is contained */
  layerId: string;
  /** is the shape locked? */
  locked?: boolean;

  /** number of slots in a stock line */
  length: number;
  /** number of stock lines */
  width: number;

  /** pallet size of the pallets in the stock zone */
  palletSize: Size3D;
  /** angle of the stock zone in degrees */
  cap: number;
  /** pattern propagation of the stock zone */
  pattern: PatternTypes;
  /** extended length of the slots (in meters) */
  extendedLength: number;
  /** pallet types accepted in the stock zone */
  palletTypes: string[];
  /** size of the slots */
  slotSize: Size3D;
  /** gap between the slots */
  gap: Size3D;
  /** gap between the slots */
  customGapSlots?: number[][];
  /** gap between the lines */
  customGapLines?: number[];

  /** how the pallets are positioned in the slots */
  referencePosX: ReferenceMethodX;
  /** numerical offset with the reference, in meters */
  referenceOffsetX: number;
  /** how the pallets are positioned in the slots */
  referencePosY: ReferenceMethodY;
  /** numerical offset with the reference, in meters */
  referenceOffsetY: number;

  /** should we display the pallets in the playground? */
  displayPallets?: boolean;
  /** which pallet model should we display in the playground? */
  palletToDisplay?: string;

  /** number of lines par group, for the repetitive pattern only */
  nbLinesPerGroup?: number;
  /** number of slots per line, for the repetitive pattern only */
  nbSlotsPerGroup?: number;
  /** gap between the lines, for the repetitive pattern only */
  spaceBetweenLinePatterns?: number;
  /** gap between the slots in a slot line, for the repetitive pattern only */
  spaceBetweenSlotPatterns?: number;

  /** should the robot scan the lines before picking and dropping? */
  enableScan: boolean;
  /** how the robot should fill the stock zone when dropping? */
  fillStrategy: fillOrEmptyStrategy;
  /** how the robot should empty the stock zone when picking? */
  emptyStrategy: fillOrEmptyStrategy;

  /**
   * Margin in front of the first slot of the stock zone, to enlarge the stock zone [m]
   * Can be useful to change the scanning area
   * see this ticket: #41934 - https://redmine.balyo.com/issues/41934
   */
  stockZoneMargin?: number;

  /** int, display priority, the greater the more on the front */
  prio: number;
}

/**
 * Properties of the stack zone
 * Not used currently
 * A stack zone is a stock zone where you can stack pallets
 */
export interface StackZoneProperties {
  type: ShapeTypes.StockZoneShape;
  name: string;
  slots: SlotArray[][];
  layerId: string;
  locked?: boolean;

  length: number;
  width: number;
  height: number;

  palletSize: Size3D;
  cap: number;
  pattern: PatternTypes;
  palletTypes: string[];
}

export enum ReferenceMethodX {
  frontEdge = 'Front Edge',
  rearEdge = 'Rear Edge',
  center = 'Center',
}

export enum ReferenceMethodY {
  leftEdge = 'Left Edge',
  rightEdge = 'Right Edge',
  center = 'Center',
}

/**
 * List of the different Rack Anomalies
 *
 * @see {isRackAnomalyId} ts-auto-guard:type-guard
 */
export enum RackAnomalyIds {
  cellNotAssigned = 'cellNotAssigned',
  cellNotEnoughSpace = 'cellNotEnoughSpace',
  cellNotEnoughHeight = 'cellNotEnoughHeight',
  cellNotEnoughMarginLoadUpright = 'cellNotEnoughMarginLoadUpright',
  beamThicknessNotEnough = 'beamThicknessNotEnough',
  palletOverflowNotEnough = 'palletOverflowNotEnough',
  palletOverflowTooMuch = 'palletOverflowTooMuch',
  uprightWidthNotEnough = 'uprightWidthNotEnough',
}

/**
 * Anomaly for the rack edition view only
 */
export interface CellAnomaly {
  type: RackAnomalyIds;
  description?: string | React.ReactNode;
  /** 0 = OK, 1 = WARNING, 2 = ERROR */
  state: 0 | 1 | 2;
}

export interface EntityAnomaly {
  /** 0 = OK, 1 = WARNING, 2 = ERROR */
  state: 0 | 1 | 2;
  /** list of the issues */
  issues: RackAnomalyIds[];
}

export interface RackProperties {
  /** "RACK" */
  type: ShapeTypes.RackShape;
  /** name of the rack */
  name: string;
  /** id of the layer */
  layerId: string;
  /** is the shape locked? */
  locked?: boolean;
  /** int, display priority, the greater the more on the front */
  prio: number;
  /** angle of the rack in degrees */
  cap: number;
  /** the depth of the rack [m] */
  depth: number;
  /** the columns contained in the rack */
  columns: RackColumn[];

  /** cell height of the cells by default [m] */
  defaultCellHeight: number;
  /** default beam thickness for the cells [m] */
  defaultBeamThickness: number;
  /** default width for the columns [m] */
  defaultColumnWidth: number;
  /** default width for the uprights [m] */
  defaultUprightWidth: number;
  /** default number of levels in every columns [1] */
  defaultNbLevels: number;
  /** default extended length in every columns [m] */
  defaultExtendedLength: number;
  /** reference value for the foot protection of the uprights */
  defaultFootProtection: FootProtection;

  /** uprights of the rack, uprights.length = columns.length + 1 */
  uprights: RackUpright[];

  /** the list of all the anomalies detected in the rack */
  anomaly?: EntityAnomaly;

  /** if the rack is a conveyor, data about these parameters, undefined if the rack is not a conveyor */
  conveyor?: ConveyorRackProperties;
}

export interface ConveyorRackProperties {
  /** id of the zone linked to the conveyor */
  zone: string;
  /** device(s) to check to do the final pick/drop on it */
  access: DoorSettings;
}

export interface RackColumn {
  /** id of the rack column */
  id: string;
  /** position of the column along the x axis [m] */
  x: number;

  /** the cells contained in the column, from the bottom to the top */
  cells: RackCell[];
  /** number of cells from the floor, redundant with cells.length, to delete? */
  nbLevels: number;
  /** extended length in front of the column [m] */
  extendedLength: number;
  /**
   * Extended length references
   * [0]: id of the segment
   * [1]: position of the segment in the column from the left in x [m]
   */
  extendedLengthSegments: [string, number][];
  /** width of the column [m] */
  width: number;
  /** offset at which the first cell starts [m] */
  startHeight: number;
  /**
   * save which properties are actively linked
   * for example if linkedProperties.width is set to true then the width of this column = the width of the master column of the rack
   * */
  linkedProperties: {
    /** is the width linked? */
    width: boolean;
    /** is the number of levels linked? */
    nbLevels: boolean;
    /** is the extended length linked? */
    extendedLength: boolean;
    /** is the start height linked? */
    startHeight: boolean;
  };
}

export interface RackUpright {
  /** width of the upright [m] */
  width: number;
  /** height of the upright [m] */
  height?: number;
  /** is the upright enabled? in theory, only uprights on the side can be disabled? */
  enabled: boolean;
  /** is the width of the upright linked to the width of the master upright of the rack? */
  linkedWidth: boolean;
  /** is the data about the protection linked with the data of the master upright of the rack? */
  linkedFootProtection: boolean;
  /**
   * data about the foot protection (only at the floor level)
   * if you don't know what it is, type "foot protection rack" in Google Image
   */
  footProtection?: FootProtection;
}

export interface FootProtection {
  /** is there a foot protection? */
  enabled: boolean;
  /** width of the foot protection [m] */
  width: number;
  /** height of the foot protection [m] */
  height: number;
  /** overflow of the foot protection [m] */
  overflow: number;
}

export interface Pallet {
  /** id of the slot */
  id: string;
  /** name of the slot */
  value: string;
  /** has the name been edited by the user? */
  user: boolean;
  /** is the position disabled **/
  disabled?: boolean;
}
export interface RackCell {
  /** id of the cell */
  id: string;
  /** id of the template? */
  cellTemplate?: string;
  /** height of the cell [m] */
  height: number;
  /** thickness of the beam below this cell [m] */
  beamThickness: number;
  /** is the height of the cell link to the master value? */
  linkedHeight?: boolean;
  /** is the beam thickness linked to the master value? */
  linkedBeamThickness?: boolean;
  /** depth of the cell [m] */
  depth?: number;
  /** deactive the cell (no cell templated needed for deactivated cells) */
  disabled?: boolean;
  /** Rack cell position names */
  names: Pallet[][];
}

/**
 * Which reference is used by the camera/perception algorithms to position the load
 * left reference = the left upright
 *
 * @see {isPerceptionReference} ts-auto-guard:type-guard
 */
export type PerceptionReference = 'left' | 'none' | 'right' | 'symmetrical';

/**
 * The cell templates to which a cell of a rack can be assigned
 * All the properties of the cell template are inehrited to the rack cell
 * The cell templates are stored in the project and can be shared between racks
 *
 * @see {isCellTemplate} ts-auto-guard:type-guard
 */
export interface RackCellTemplate {
  /** id of the cell template */
  id: string;
  /** name of the cell template */
  name: string;
  /**
   * theorical width of the cell template [m]
   *
   * /!\ only used for the display in the cell template edition
   * actually it is the column width that is used
   * */
  width: number;
  /**
   * theorical height of the cell template [m]
   *
   * /*\ only used for the display in the cell template edition
   * actually it is the cell height that is used
   * */
  height: number;

  /** Maximum load height allowed in the cell [m] */
  maximumLoadHeight: number;

  /** [m] */
  approachDistance: number;

  /** accepted pallets in the cell */
  palletTypes: string[];
  /** nominal distance between the front of the pallet end the rack (overhang) [m] */
  palletOverflow: number;
  /** distance between the front of the pallet and the front of the cell [m]
   * default value: 1.2 m (EURO / INDU pallets)
   */
  loadLength: number;

  /** if there's something close to the left upright that we cannot touch (can be the foot protection for example) [m] */
  leftObstacle: number;
  /** if there's something close to the right upright that we cannot touch (can be the foot protection for example) [m] */
  rightObstacle: number;

  /** positions of the loads in the cell */
  loads: CellLoad[];
  /**
   * load positions currently selected by the user
   * cell templates can have multiple loads (mix pallet)
   * in the racks, only one load is selected at once (as well as in the cell template view)
   * this is the currently displayed load
   * This is an index, to get the load cellTemplate.loads[cellTemplate.selectedLoad]
   * */
  selectedLoad: number;
  /** color of the cell template, to help the user to distinguish all the cell templates */
  color: string;

  /** properties related to the perception */
  perception: CellTemplatePerceptionProperties;

  /**
   * Add an offset between the front of the beam and the actual value seen by the perception camera
   * 0 is a fine value for most of the cases, changing the value can be useful for certain beams such as the IPN ones
   * More info here: https://redmine.balyo.com/issues/40537
   */
  beamDistanceToRackFront?: number;
  /**
   * If true, only for conveyors
   */
  forConveyor?: boolean;
}

/**
 * A CellLoad is defined by three parameters:
 * - N: the number of loads in the cell
 * - a: the distance between an upright and the closest load
 * - W: the width of the load
 * Then we are able to compute the positions of the loads in the cell
 *
 * The idea is to be able to create as many positions as we want, this is quite useful if the user
 * wants to place pallets of different types in the cell.
 */
export interface CellLoad {
  /** The name of the NaW load */
  name: string;
  /** number of loads in the cell */
  N: number;
  /** distance from the left upright and the left load [m] */
  a1: number;
  /** distance from the right upright and the right load (if not defined = a1) [m] */
  a2?: number;
  /** width of a load [m] */
  W: number;
  /** which reference needs to be taken by the camera for this position
   * left reference = it uses the left upright as reference to position the load
   */
  references: PerceptionReference[];
  /** is the pallet centered?
   * allowed only for N=1
   * if true, we do not use a1 anymore and compute the value
   */
  center?: boolean;

  /**
   * nominal distance between the front of the pallet end the rack (overhang) [m]
   * this value is optional because most of the time the value is inherited from the cell template
   * this value is applied if specified, otherwise the value of the cell template is used
   * */
  palletOverflow?: number;

  /**
   * Length of the load [m]
   * If undefined = the value inherited from the cell template
   */
  length?: number;
}

/**
 * Parameters of a Rack related to the 3D camera
 */
export interface CellTemplatePerceptionProperties {
  /** height of the inspection point (guess pos in Z), between the top of the beam and the inspection point [m] */
  startHeight: number;
  /** depth of the inspection point (guess pos in X), between the face of the beam and the inspection point [m] */
  startDepth: number;

  /** Required minimum control of the slot height before drop [m] */
  minimumHeightVerification: number;
  /** Required minimum clearance on top and bottom face of the load to authorize drop [m] */
  minimumVerticalClearanceForDrop: number;
  /** Minimal diameter of objects to be detected (All smaller objects will be ignored) [m] */
  freeSpaceMinObjectSize: number;
  /** Required minimum clearance on left and right sides of the load to authorize drop [m] */
  minimumLateralClearanceForDrop: number;
  /** Required minimum clearance behind the load to authorize drop [m] */
  minimumDepthClearanceForDrop: number;
  /** Maximal difference between beam front face theoretical location and reference found by drop control [m] */
  beamFaceXTolerance: number;
  /** Area above the beam where we ignore the points because we think it can be the beam itself [m] */
  beamUpMarginObstacle?: number;
  /** Maximal difference between lateral reference theoretical location and reference found by drop control [m] */
  uprightYTolerance: number;
  /** Maximal difference between beam top face theoretical location and reference found by drop control [m] */
  beamHeightZTolerance: number;
  /** Tolerance on the allowed maximum overhang for the load after drop (in case the available depth is not enough) [m] */
  overhangTolerance: number;
  /** Offsets to apply on the size of the guessPose during load recognition [m] */
  initialSolution: [number, number, number];
  /** Thresholds for the watchdog diagnosis [%] */
  rejectPercentage: {
    model: number; // in [0, 1]
    feet: number; // in [0, 1]
    pockets: number; // in [0, 1]
  };
  /** Multiplier to apply to minObjectSize that gives the size of the cross-shape [1] */
  minObstacleMultiplier: number;
  /** boolean that activates the said projection */
  cameraProjectionBeforeInspectionPlane: boolean;
  /** boolean that activates the said projection */
  horizontalProjectionOnInspectionPlane: boolean;
  /** boolean that activates the said projection */
  horizontalProjection: boolean;
  /** Enables a diagnosis in freeSpace analysis : if we detect an obstacle on top of the inspection point, then we trigger an error */
  flyingObjectDetection: boolean;
  /** Enable crossShaped method */
  activateCrossShapedMethod: boolean;
  /**
   * Enable depth/lift/shift corrections during the pick sequence on the given slot
   *
   * 0 = no correction
   * 1 = correction
   * */
  relPickActivateCorr: {
    depth: 0 | 1;
    lift: 0 | 1;
    shift: 0 | 1;
  };
  /**
   * Enable depth/left/shift corrections during the drop sequence on the given slot
   *
   * 0 = no correction
   * 1 = absolute correction
   * 2 = relative correction
   * */
  relDropActivateCorr: {
    depth: 0 | 1 | 2;
    lift: 0 | 1 | 2;
    shift: 0 | 1 | 2;
  };
  /** should the robot check the position of the pallet with its rangefinder before a pick? */
  rangefinderDetection: boolean;
  /** should the robot enable the perception algorithms before picking? */
  enablePerceptionPick: boolean;
  /** should the robot enable the perception algorithms before droping? (freespace3d) */
  enablePerceptionDrop: boolean;
}

/**
 * The possible types of the devices
 *
 * @see {isDeviceType} ts-auto-guard:type-guard
 */
export type DeviceType =
  | '3BTCombox'
  | 'IOECombox'
  | 'mobileDockingStation'
  | 'smartCharger'
  | 'modbusDevice'
  | 'comboxGen2';
export type PinType = {
  name: string;
};

/**
 * Choosing the value Mode between Bit: [input, output, 0, 0] or Register: [0, 0, input, output].
 */
export type ModbusType = 'Bit' | 'Register';
/**
 * Parameters for the Devices objects
 * Devices are mainly Balyo Comboxes
 * They also can be automata, etc.
 */
/**
 * @see {isNetworkType} ts-auto-guard:type-guard
 */
export type NetworkType = 'Standalone' | 'Gateway' | 'Device';

/**
 * @see {isComboxVersionType} ts-auto-guard:type-guard
 */
export type ComboxVersionType = 'Standard' | 'Extended' | 'Button';

export interface DeviceProperties {
  /** = "DEVICE" */
  type: ShapeTypes.DeviceShape;
  /** id of the layer in which the shape is */
  layerId: string;
  /** is the shape locked? */
  locked?: boolean;

  /** the type of the device, if it is a combox or another device */
  deviceType: DeviceType;
  /** IP address of the device */
  IP: string;

  /** the name of the device in the code (must be unique across all devices (used for code and reference in the environment) */
  name: string;
  /** the display name of the device in the user interfaces */
  displayName: string;

  /** data (for humans) about the different pins of the combox (to what it is connected, etc.) */
  pinsIn?: PinType[];
  /** data (for humans) about the different pins of the combox (to what it is connected, etc.) */
  pinsOut?: PinType[];

  /** Period between two consecutive checks of the device (ms) and the name might change to sample period base on the NEW4X version.*/
  frequency?: number;

  /**
   * Should we display the device in the robot manager interface?
   * DrawDeviceCarto parameter
   */
  displayUI?: boolean;

  /**
   * port of the device
   *
   * 502 value by default
   */
  port?: number;
  /**
   * value Mode between Bit and Register for IO Count and addresses of the device
   * Associated training : https://elearning.balyo.com/content/course/474/lesson/536/content/1817
   * Bit value by default
   */
  modbusType?: ModbusType;
  /**
   * Used for Modbus devices only
   * Number of IO: can be scalar for the same number of binary IO, [nI,No] for different numbers of binary IO, [nbI,nbO,nrI,nro] to declare the number of register IO (nri and nro)
   */

  /**
   * Used for Modbus devices only
   * First address of every block of IO: [fabI,fabO,farI,farO] the first address of every type of IO.
   */
  ioAddresses?: number[];
  /**
   * Used for Modbus devices only
   * Modbus function codes of every block of IO: [fcbI,fcbO,fcrI,fcrO] the function code of every type of IO.
   */
  functionCodes?: number[];
  /**
   * Used for Modbus devices only
   * Shall the output of the device be set only by the robot manager? Is the robot manager allow to overwrite an output value if it detects an incoherency.
   */
  keepWritingOutputs?: boolean;

  /** int, display priority, the greater the more on the front */
  prio: number;
  /** Lora ID of the device */
  loraID?: string;
  /** Network value of the device which are standalone, Gateway and Device */
  network?: NetworkType;
  /** combox version of the comboxGen2 device which are standard, Extended or 3 buttons */
  comboxVersion?: ComboxVersionType;
  /** Id of the comboxGen2gateway and its IP will be the IP of the comboxGen2Device*/
  gateway?: string;
}

/**
 * @see {isNoteDisplayMode} ts-auto-guard:type-guard
 */
export type NoteDisplayMode = 'icon' | 'text';

export interface NoteProperties {
  /** = "NOTE" */
  type: ShapeTypes.NoteShape;
  locked?: boolean;
  // here with this boolean we can choose to show icon or text on circuit
  displayMode: NoteDisplayMode;
  /** int, display priority, the greater the more on the front */
  prio: number;
  /** id of the layer in which the shape is */
  layerId: string;
  /** name of the shape */
  name: string;

  size: number; // size in px
}

/**
 * @see {isLibTrackItinerary} ts-auto-guard:type-guard
 */
export interface LibTrackItinerary {
  /** distance in meters */
  distance?: number;
  /** cost of the itinerary (for internal SDK computation) */
  cost?: number;
  /** duration in seconds */
  duration?: number;
  /** list of points of the itinerary */
  listOfPosition?: {
    /** x coordinate in meters */
    x: number;
    /** y coordinate in meters */
    y: number;
    /** orientation in radian between [-pi, pi] */
    heading: number;
  }[];
  /** ids of the portions */
  listOfPortionID?: number[];
  /** id of the error (0 = OK) */
  errorID: number;
  /** error message */
  error: string;
}

/**
 * @see {isCheckTracklessResult} ts-auto-guard:type-guard
 */
export interface CheckTracklessResult {
  /** id of the error (0 = OK) */
  errorID: number;
  /** the error in plain text */
  error?: string;
  /** the distance of the portion to another rack (meters) */
  distanceToOtherRack?: number;

  /** all the computed trajectories with their ids  */
  tracklessTrajectories?: {
    [key: string]:
      | {
          /** distance of the trackless itinerary (meters) */
          distance: number;
          /** list of portions of the itinerary */
          listOfPortionID: number[];
          /** list of the points of the itinerary */
          listOfPosition: {
            /** x coordinate in meters */
            x: number;
            /** y coordinate in meters */
            y: number;
            /** orientation in radian between [-pi, pi] */
            heading: number;
          }[];
        }
      | {
          /** error of the trackless trajectory */
          error: string;
        }
      | undefined;
  };
}

/**
 * @see {isGetAllCantonsResult} ts-auto-guard:type-guard
 */
export interface GetAllCantonsResult {
  noStopCanton?: CantonsDict;
  noStopZoneCanton?: CantonsDict;
}

/**
 * @see {isCantonAtPoint} ts-auto-guard:type-guard
 */
export interface CantonAtPoint {
  /** data about the current canton analyzed */
  currentCanton?: CantonsDict;
  /** data about linked by collision cantons related to the current canton */
  linkedByCollision?: CantonsDict;
  /** data about linked by deadend cantons related to the current canton */
  linkedByDeadend?: CantonsDict;
  /** data about linked by zone cantons related to the current canton */
  linkedByZone?: CantonsDict;
  /** data about the cantons taken by the robot pattern when the robot is in manual (far enough from the track) */
  manualCantons?: CantonsDict;

  /** Pattern of the robot at the given position (list of points) */
  pattern?: {
    /** x coordinate of the point of the pattern in meters */
    x: number;
    /** y coordinate of the point of the pattern in meters */
    y: number;
  }[];
}

/**
 * @see {isDeadendsAtPoint} ts-auto-guard:type-guard
 */
export interface DeadendsAtPoint {
  manoeuver?: CantonsDict;

  kernelNoStop?: CantonsDict;

  neutral?: CantonsDict;

  blocked?: CantonsDict;
}

export type Stations = Station[];
